import {
  Avatar,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../../components/text/CustomText";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { MY_ORDERS } from "../../../utils/constants";
import ArrowBack from "../../../assets/images/black-left.svg";
import ArrowForward from "../../../assets/images/black-right.svg";
import GradientText from "../../../components/text/GradientText";
import CustomButton from "../../../components/button/CustomButton";
import OrderDetails from "./OrderDetails";

function OrderTable({ status }: any) {
  const { t } = useTranslation();

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const getBgColor = (status: string) => {
    switch (status) {
      case "Completed":
        return "#56CE77";
      case "In Process":
        return "#FFA333";
      case "Cancel":
        return "#FF6574";
      case "Cancelled":
        return "#FF6574";
      default:
        return "extsy.neutral300";
    }
  };

  return (
    <Flex flexDir="column" gap="24px">
      <TableContainer minH="55vh">
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Coin Type"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Order ID"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text="Amount"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text="Price"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text="Quantity"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Time / Posted by"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Status"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={t("actions")}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {MY_ORDERS.map((order: any, index) => (
              <Tr key={index}>
                <Td
                  height="64px"
                  borderColor="extsy.neutral900"
                  borderRight="1px solid"
                  py="0"
                >
                  <Flex gap="12px" align="center">
                    <Avatar src={order?.coin?.icon} boxSize="24px" />
                    <Flex gap="8px" flexDir="column">
                      <CustomText
                        text={order.coin?.symbol}
                        size="14px"
                        color="extsy.baseWhite"
                      />
                      <CustomText
                        text={`${order?.coin?.name}`}
                        size="12px"
                        color="extsy.text5"
                      />
                    </Flex>
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex align="center" gap="6px">
                    <CustomText
                      text={order.order?.type}
                      size="12px"
                      color={
                        order.order?.type === "Buy" ? "#15B097" : "extsy.danger"
                      }
                    />
                    <CustomText text="|" size="12px" color="extsy.neutral900" />
                    <CustomText text={order?.order?.id} size="12px" />
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText
                    text={order?.amount}
                    size="14px"
                    weight="500"
                    align="end"
                  />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText
                    text={order?.price}
                    size="14px"
                    weight="500"
                    align="end"
                  />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText
                    text={order?.qty}
                    size="14px"
                    weight="500"
                    align="end"
                  />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText text={order?.time} size="14px" weight="500" />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex
                    w="90px"
                    h="24px"
                    align="center"
                    justify="center"
                    borderRadius="30px"
                    bg={`${getBgColor(
                      status === "All" ? order?.status : status
                    )}1A`}
                  >
                    <CustomText
                      text={status === "All" ? order.status : status}
                      size="10px"
                      color={getBgColor(
                        status === "All" ? order?.status : status
                      )}
                    />
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomButton
                    title="View Order"
                    bg="extsy.neutral800"
                    color="extsy.baseWhite"
                    height="28px"
                    size="12px"
                    onClick={onViewOpen}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        <CustomText
          text={`1-10 ${t("of")} 50`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />
        <Flex gap="8px">
          <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="2" size="14px" weight="500" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="3" size="14px" weight="500" />
          </Flex>
          <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
        </Flex>
      </Flex>
      <OrderDetails isOpen={isViewOpen} onClose={onViewClose} />
    </Flex>
  );
}

export default OrderTable;
