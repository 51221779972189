import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import AMLCard from "./AMLCard";
import CheckCounter from "./CheckCounter";
import CustomText from "../../components/text/CustomText";
import AMLHistory from "./AMLHistory";

function AmlCheck() {
  return (
    <div>
      <Flex gap="24px" width="100%">
        <AMLCard />
        <CheckCounter />.
      </Flex>
      <Box
        p="24px"
        bg="extsy.neutral1000"
        boxShadow=" 0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
        borderRadius="24px"
        mt="24px"
      >
        <Flex flexDir="column" gap="24px">
          <CustomText
            text="Request History"
            size="24px"
            lh="34px"
            weight="700"
            family="Titillium Web"
            color="extsy.baseWhite"
          />
          <AMLHistory />
        </Flex>
      </Box>
    </div>
  );
}

export default AmlCheck;
