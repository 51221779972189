import React, { useState } from "react";
import CustomText from "../text/CustomText";
import { Flex } from "@chakra-ui/react";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";

function Collateral({ setIsCollateralOpen, isCollateralOpen }: any) {
  const [activeCollateral, setActiveCollateral] = useState("Cross");

  return (
    <>
      <Flex
        cursor="pointer"
        gap="12px"
        align="center"
        justify="space-between"
        bg="extsy.neutral900"
        boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
        borderRadius="999px"
        width="132px"
        h="38px"
        ps="24px"
        pe="10px"
        onClick={setIsCollateralOpen}
      >
        <CustomText
          text={activeCollateral}
          size="12px"
          color="extsy.baseWhite"
          cursor="pointer"
        />
        <IconChevronDown size="16px" color="#fafafa" />
      </Flex>
      <Flex
        flexDir="column"
        gap="12px"
        p="16px"
        borderRadius="16px"
        border="1px solid"
        borderColor="extsy.neutral800"
        bg="extsy.neutral900"
        pos="absolute"
        top="0"
        mt="45px"
        zIndex="15"
        width="100%"
        display={isCollateralOpen ? "block" : "none"}
      >
        <Flex
          cursor="pointer"
          py="8px"
          flexDir="column"
          gap="10px"
          onClick={() => {
            setActiveCollateral("Cross");
            setIsCollateralOpen(false);
          }}
        >
          <Flex align="center" justify="space-between" cursor="pointer">
            <CustomText
              text="Cross"
              size="14px"
              color="extsy.baseWhite"
              cursor
            />
            {activeCollateral === "Cross" && (
              <IconCheck size="16px" color="#FAFAFA" />
            )}
          </Flex>
          <CustomText
            text="Use shared collateral and risk across multiple positions"
            size="14px"
          />
        </Flex>
        <Flex
          cursor="pointer"
          py="8px"
          flexDir="column"
          gap="10px"
          onClick={() => {
            setActiveCollateral("Isolated");
            setIsCollateralOpen(false);
          }}
        >
          <Flex align="center" justify="space-between">
            <CustomText
              text="Isolated"
              size="14px"
              color="extsy.baseWhite"
              cursor
            />
            {activeCollateral === "Isolated" && (
              <IconCheck size="16px" color="#FAFAFA" />
            )}
          </Flex>
          <CustomText
            text="Use distinct collateral for each position to isolate risk."
            size="14px"
          />
        </Flex>
      </Flex>
    </>
  );
}

export default Collateral;
