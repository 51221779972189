import { Flex, Image, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import LogoIcon from "../assets/images/logo-icon.svg";

function ForgotLayout() {
  //   const [isLessThan768] = useMediaQuery("(max-height: 768px)");
  const navigate = useNavigate();
  const [isLessThan1920] = useMediaQuery("(max-width: 1919px)");

  useEffect(() => {
    if (isLessThan1920) {
      navigate("/coming-soon");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLessThan1920]);

  return (
    <Flex bg="extsy.bg" minH="100vh" flexDir={"column"} p="24px 80px">
      <Image
        draggable={false}
        src={LogoIcon}
        alt="logo"
        width="55px"
        height="40px"
      />
      <Flex justify={"center"} align={"center"} minH="90vh">
        <Outlet />
      </Flex>
    </Flex>
  );
}

export default ForgotLayout;
