import { Button, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";

function SocialLoginButton({ icon, title, width }: any) {
  return (
    <Button
      display="flex"
      alignItems="center"
      gap="8px"
      height="48px"
      width={{ base: "", md: width || "121px" }}
      borderRadius="999px"
      bg="extsy.neutral1000"
      _hover={{
        bg: "extsy.neutral1000",
      }}
    >
      <Image src={icon} alt={title} />
      <CustomText
        text={title}
        color="extsy.baseWhite"
        size="14px"
        cursor="pointer"
        mt="3px"
        display={{ base: "none", md: "block" }}
      />
    </Button>
  );
}

export default SocialLoginButton;
