import { Input } from "@chakra-ui/react";
import React from "react";

function SwapAmountInput({ noBorder, fontSize }: any) {
  return (
    <Input
      type="number"
      border="none"
      borderRadius="0"
      borderBottom={noBorder ? "0px solid" : "1px solid"}
      borderColor="extsy.neutral400"
      _hover={{ borderColor: "extsy.neutral400" }}
      _focusVisible={{ borderColor: "extsy.neutral400" }}
      pl="0px"
      fontSize={fontSize || "27.4px"}
      fontWeight="700"
      color="extsy.white"
      fontFamily="Titillium Web"
      placeholder="-"
    />
  );
}

export default SwapAmountInput;
