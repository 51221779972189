import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import GoogleIco from "../../assets/images/google-ico.svg";
import {
  IconArrowUpRight,
  IconCopy,
  IconEye,
  IconEyeOff,
} from "@tabler/icons-react";
import KycVerificationVector from "../../assets/images/kyc-verificaition-vector.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import CustomModal from "../../components/modal/customModal";
import QRCode from "react-qr-code";
import SecurityImg from "../../assets/images/auth-security-Img.svg";
import CustomVerificationInput from "../../components/form/customVerificationInput";
import CustomInput from "../../components/form/CustomInput";

function PrivacySecurity() {
  const {
    isOpen: is2FAOpen,
    onOpen: on2FAOpen,
    onClose: on2FAClose,
  } = useDisclosure();
  const {
    isOpen: isVerifyAuthOpen,
    onOpen: onVerifyAuthOpen,
    onClose: onVerifyAuthClose,
  } = useDisclosure();
  const {
    isOpen: isUpdatePasswordOpen,
    onOpen: onUpdatePasswordOpen,
    onClose: onUpdatePasswordClose,
  } = useDisclosure();

  const [payload, setPayload] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState<any>({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const togglePassword = (name: string) => {
    setIsPasswordVisible({
      ...isPasswordVisible,
      [name]: !isPasswordVisible[name],
    });
  };

  return (
    <>
      <Grid
        gap="24px"
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(12, 1fr)"
      >
        <GridItem colSpan={6}>
          <Flex
            gap="32px"
            p="32px"
            flexDir="column"
            borderRadius="24px"
            bg="extsy.neutral900"
            border="1px solid"
            borderColor="extsy.neutral1000"
          >
            <CustomText
              text="2FA"
              size="24px"
              lh="34px"
              weight="700"
              family="Titillium Web"
              color="extsy.white"
            />
            <Flex align="center" justify="space-between" mt="8px">
              <Flex flexDir="column" gap="8px">
                <CustomText text="Authenticator" color="extsy.baseWhite" />
                <CustomText text="6-digits one-time code" size="12px" />
              </Flex>
              <Switch colorScheme="green" size="md" onChange={on2FAOpen} />
            </Flex>
            <Box h="1px" bg="extsy.neutral1000" />
            <Flex gap="16px" flexDir="column">
              <CustomText text="Supported App" color="extsy.baseWhite" />
              <Flex align="center" justify="space-between">
                <Flex align="center" gap="10px">
                  <Image src={GoogleIco} alt="google" boxSize="24px" />
                  <CustomText
                    text="Google Authenticator"
                    color="extsy.baseWhite"
                  />
                </Flex>
                <IconArrowUpRight size="24px" color="#E35CAD" />
              </Flex>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem colSpan={6}>
          <Flex
            gap="32px"
            p="32px"
            flexDir="column"
            borderRadius="24px"
            bg="extsy.neutral900"
            border="1px solid"
            borderColor="extsy.neutral1000"
            minH="286px"
            h="100%"
            justify="space-between"
          >
            <CustomText
              text="Login Info"
              size="24px"
              lh="34px"
              weight="700"
              family="Titillium Web"
              color="extsy.white"
            />
            <Flex gap="24px" flexDir="column">
              <Flex
                ps="22px"
                pe="16px"
                h="56px"
                align="center"
                bg="extsy.neutral1000"
                borderRadius="8px"
              >
                <CustomText
                  text="user@email.com"
                  color="extsy.baseWhite"
                  family="Titillium Web"
                />
              </Flex>
              <Box height="1px" bg="extsy.neutral1000" />
              <Flex
                ps="22px"
                pe="16px"
                h="56px"
                align="center"
                justify="space-between"
                bg="extsy.neutral1000"
                borderRadius="8px"
              >
                <CustomText
                  text="**************"
                  color="extsy.baseWhite"
                  family="Titillium Web"
                />
                <Flex
                  align="center"
                  justify="center"
                  h="32px"
                  w="130px"
                  cursor="pointer"
                  borderRadius="41px"
                  bg="extsy.neutral900"
                  onClick={onUpdatePasswordOpen}
                >
                  <CustomText
                    text="Change Password"
                    size="12px"
                    lh="22px"
                    family="Titillium Web"
                    weight="600"
                    cursor
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem colSpan={12}>
          <Flex
            gap="12px"
            p="32px"
            flexDir="column"
            justify="center"
            borderRadius="24px"
            bg="extsy.neutral900"
            border="1px solid"
            borderColor="extsy.neutral1000"
            minH="383px"
            h="100%"
          >
            <CustomText
              text="KYC Verification"
              size="24px"
              lh="34px"
              weight="700"
              family="Titillium Web"
              color="extsy.white"
            />
            <Flex gap="24px" align="center" justify="space-between">
              <Flex flexDir="column" gap="24px">
                <CustomText text="Verify your identity through SumSub to get more from your current level." />
                <PrimaryButton title="Verify" size="14px" maxW="216px" />
              </Flex>
              <Image
                src={KycVerificationVector}
                alt="kyc"
                w="310px"
                h="205px"
              />
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
      <CustomModal
        isOpen={is2FAOpen}
        onClose={on2FAClose}
        onSubmit={() => {
          on2FAClose();
          onVerifyAuthOpen();
        }}
        headerText={"2 Factor Authentication"}
        btnText={"Enable Now"}
        closeBtntext={"Cancel"}
        width={"508px"}
        bgtopBlur="extsy.primary"
      >
        <Flex p="24px" flexDir="column" gap="24px">
          <CustomText
            text="Security QR Code"
            size="14px"
            color="extsy.baseWhite"
            align="center"
          />
          <Flex
            bg="extsy.bg"
            height="260px"
            width="260px"
            borderRadius="16px"
            align="center"
            justify="center"
            p="18px"
            mx="auto"
          >
            <QRCode value="hey" bgColor="transparent" fgColor="#fafafa" />
          </Flex>
          <CustomText
            text="For more security, enable on authenticator app"
            size="14px"
            color="extsy.baseWhite"
            align="center"
          />
          <Flex mt="8px" gap="10px" flexDir="column">
            <CustomText text="Security Key" size="12px" />
            <Flex
              align="center"
              gap="20px"
              justify="space-between"
              h="56px"
              px="16px"
              bg="extsy.bg"
              borderRadius="12px"
            >
              <CustomText
                text="uodue3849903ns3h3h8s9"
                color="extsy.baseWhite"
              />
              <IconCopy size="24px" color="#F6DBD4" cursor="pointer" />
            </Flex>
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isVerifyAuthOpen}
        onClose={onVerifyAuthClose}
        onSubmit={onVerifyAuthClose}
        headerText={"Verify Authentication"}
        btnText={"Verify Security Code"}
        closeBtntext={"Cancel"}
        width={"508px"}
        bgtopBlur="extsy.primary"
      >
        <Flex p="24px" flexDir="column" align="center" gap="32px">
          <Image src={SecurityImg} alt="security" w="139px" h="120px" />
          <Box py="24px">
            <CustomVerificationInput placeholder={0} w="63px" gap="6px" />
          </Box>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isUpdatePasswordOpen}
        onClose={onUpdatePasswordClose}
        onSubmit={onUpdatePasswordClose}
        headerText={"Update Password"}
        btnText={"Update Password"}
        closeBtntext={"Cancel"}
        width={"600px"}
        bgtopBlur="extsy.primary"
      >
        <Flex p="40px 24px" flexDir="column" align="center" gap="50px">
          <CustomText
            text="Choose a strong & secure alpha-numeric password with special characters minimum length of 8."
            align="center"
          />
          <Flex flexDir="column" gap="24px" w="100%">
            <Flex flexDir="column">
              <CustomText
                text="Current Password"
                color="extsy.neutral500"
                size="12px"
              />
              <CustomInput
                placeholder="**************"
                value={payload.currentPassword}
                onChange={(e: any) =>
                  setPayload({ ...payload, currentPassword: e.target.value })
                }
                type={isPasswordVisible?.currentPassword ? "text" : "password"}
                rightIco={
                  isPasswordVisible?.currentPassword ? (
                    <IconEyeOff
                      onClick={() => {
                        togglePassword("currentPassword");
                      }}
                      cursor="pointer"
                    />
                  ) : (
                    <IconEye
                      onClick={() => {
                        togglePassword("currentPassword");
                      }}
                      cursor="pointer"
                    />
                  )
                }
              />
            </Flex>
            <Flex flexDir="column">
              <CustomText
                text="New Password"
                color="extsy.neutral500"
                size="12px"
              />
              <CustomInput
                placeholder="**************"
                value={payload.newPassword}
                onChange={(e: any) =>
                  setPayload({ ...payload, newPassword: e.target.value })
                }
                type={isPasswordVisible?.newPassword ? "text" : "password"}
                rightIco={
                  isPasswordVisible?.newPassword ? (
                    <IconEyeOff
                      onClick={() => {
                        togglePassword("newPassword");
                      }}
                      cursor="pointer"
                    />
                  ) : (
                    <IconEye
                      onClick={() => {
                        togglePassword("newPassword");
                      }}
                      cursor="pointer"
                    />
                  )
                }
              />
            </Flex>
            <Flex flexDir="column">
              <CustomText
                text="Repeat New Password"
                color="extsy.neutral500"
                size="12px"
              />
              <CustomInput
                placeholder="**************"
                value={payload.confirmPassword}
                onChange={(e: any) =>
                  setPayload({ ...payload, confirmPassword: e.target.value })
                }
                type={isPasswordVisible?.confirmPassword ? "text" : "password"}
                rightIco={
                  isPasswordVisible?.confirmPassword ? (
                    <IconEyeOff
                      onClick={() => {
                        togglePassword("confirmPassword");
                      }}
                      cursor="pointer"
                    />
                  ) : (
                    <IconEye
                      onClick={() => {
                        togglePassword("confirmPassword");
                      }}
                      cursor="pointer"
                    />
                  )
                }
              />
            </Flex>
          </Flex>
        </Flex>
      </CustomModal>
    </>
  );
}

export default PrivacySecurity;
