import React,{useState} from "react";
import {
  Step,
  StepIndicator,
  StepNumber,
  StepStatus,
  Stepper,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import CustomText from "../../components/text/CustomText";
import EnterEmail from "./EnterEmail";
import VerifyEmail from "./VerifyEmail";
import UpdatePassword from "./UpdatePassword";
import { useTranslation } from "react-i18next";


const steps = [
  { title: "Enter Email" },
  { title: "Verify your email" },
  { title: "Update Password" },
];
const ForgotPassword = () => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0); 
  const navigate = useNavigate();
  const handleNextStep = () => {
    switch (activeStep) {
      case 0:
        setActiveStep(1); // Move to Verify Email step
        navigate('/auth/forgot-password/verify-email'); // Navigate to Verify Email
        break;
      case 1:
        setActiveStep(2); // Move to Update Password step
        navigate('/auth/forgot-password/update-password'); // Navigate to Update Password
        break;
      case 2:
        // Optionally, you can reset or do nothing here if it's the last step
        break;
      default:
        break;
    }
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <EnterEmail onNext={handleNextStep} />;
      case 1:
        return <VerifyEmail  onNext={handleNextStep}/>;
      case 2:
        return <UpdatePassword  />;
      default:
        return null;
    }
  };
  return (
    <Flex minW={"722px"}  flexDir="column" alignItems="center" gap={"80px"}>
      {/* Stepper navigation */}
      <Flex
        minW={"722px"}
        justify={"space-between"}
        align={"center"}
        width="100%"
      >
        <Stepper index={activeStep}>
          {steps.map((step, index) => (
            <Flex key={index} alignItems="center" justifyContent="center">
              <Step>
                <StepIndicator
                   sx={{
                    '[data-status=complete] &': {
                      background: 'extsy.primary',
                      color:"extsy.bg"
                    },
                    '[data-status=active] &': {
                       background: 'extsy.primary',
                      color:"extsy.bg"
                    },
                    '[data-status=incomplete] &': {
                      background: 'extsy.neutral1000',
                      color: 'extsy.neutral300',
                    },
                  }}
                  fontFamily={"Metropolis"}
                  fontSize={"12px"}
                  fontWeight={"700"}
                  border={"none"}
                  minW={"32px"}
                  minH={"32px"}
                >
                  <StepStatus
                    complete={<StepNumber />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0" mx="10px">
                  <CustomText
                    text={t(step.title)}
                    fontFamily={"Metropolis"}
                    color={
                      index === activeStep
                        ? "extsy.baseWhite"
                        : "extsy.neutral600"
                    }
                  />
                </Box>
              </Step>

              {index < steps.length - 1 && (
                <ActiveBorder
                  bg={index < activeStep ? "extsy.primary" : "extsy.neutral300"}
                  stepTitle={step.title}
                />
              )}
            </Flex>
          ))}
        </Stepper>
      </Flex>

      {/* Render the corresponding component based on the active step */}
      <Box mt="40px" maxW="400px">
        {renderStepContent()}
      </Box>
    </Flex>
  );
};
const ActiveBorder = ({ bg, stepTitle }: any) => {
  return <Box bg={bg} w={`86px`} h="2px" borderRadius="full"></Box>;
};

export default ForgotPassword;
