import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../text/CustomText";
import { AUTH_SLIDES_CONTENT } from "../../utils/constants";

function AuthSlider() {
  const [isLessThan768] = useMediaQuery("(max-height: 768px)");
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prev) => (prev === 2 ? 0 : prev + 1));
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Flex flexDir="column" gap={isLessThan768 ? "150px" : "200px"} maxW="530px">
      <Flex flexDir="column" gap="32px">
        <CustomText
          text={AUTH_SLIDES_CONTENT[activeSlide]?.title}
          size="24px"
          color="extsy.baseWhite"
          lh="24px"
          family="Titillium Web"
        />
        <CustomText
          text={AUTH_SLIDES_CONTENT[activeSlide]?.description}
          color="extsy.neutral300"
          lh="19px"
          family="Metropolis"
        />
      </Flex>
      <Flex align="center" gap="16px">
        <CustomText
          text="01"
          color={activeSlide === 0 ? "extsy.primary" : "extsy.baseWhite"}
          weight="700"
          opacity={activeSlide === 0 ? "1" : "0.2"}
        />
        {activeSlide === 0 && <ActiveBorder />}
        <CustomText
          text="02"
          color={activeSlide === 1 ? "extsy.primary" : "extsy.baseWhite"}
          weight="700"
          opacity={activeSlide === 1 ? "1" : "0.2"}
        />
        {activeSlide !== 0 && <ActiveBorder />}
        <CustomText
          text="03"
          color={activeSlide === 2 ? "extsy.primary" : "extsy.baseWhite"}
          weight="700"
          opacity={activeSlide === 2 ? "1" : "0.2"}
        />
      </Flex>
    </Flex>
  );
}

const ActiveBorder = () => {
  return <Box bg="extsy.primary" w="70px" h="2px" borderRadius="full"></Box>;
};

export default AuthSlider;
