import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";

function ExtsyStats() {
  const { t } = useTranslation();
  return (
    <Flex
      flexDir="column"
      gap="40px"
      borderRadius="24px"
      border="1px solid"
      borderColor="extsy.neutral1000"
      bg="extsy.neutral1000"
      p="32px"
      pos="relative"
      overflow="hidden"
    >
      <CustomText
        text={t("stats")}
        size="24px"
        weight="700"
        color="extsy.white"
      />
      <Flex gap="40px" align="center" zIndex="1000">
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText
            text={t("totalEarned")}
            color="extsy.baseWhite"
            size="14px"
          />
          <CustomText
            text={"0.00 BTC"}
            size="32px"
            weight="700"
            color="extsy.baseWhite"
          />
          <CustomText text={"~ $ 0.00"} size="14px" color="extsy.baseWhite" />
        </Flex>
        <Box height="88px" width="1px" bg="extsy.neutral900" />
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText
            text={t("available")}
            color="extsy.baseWhite"
            size="14px"
          />
          <CustomText
            text={"0.00 BTC"}
            size="32px"
            weight="700"
            color="extsy.baseWhite"
          />
          <CustomText text={"~ $ 0.00"} size="14px" color="extsy.baseWhite" />
        </Flex>
        <Box height="88px" width="1px" bg="extsy.neutral900" />
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText
            text={t("currentCashback")}
            color="extsy.baseWhite"
            size="14px"
          />
          <CustomText
            text={"0.00 BTC"}
            size="32px"
            weight="700"
            color="extsy.baseWhite"
          />
          <CustomText
            text={t("ofTheVolume")}
            size="14px"
            color="extsy.baseWhite"
          />
        </Flex>
      </Flex>
      <Box
        pos="absolute"
        bg="#4845FF"
        height="486px"
        width="486px"
        borderRadius="486px"
        filter="blur(97px)"
        zIndex="1"
        top="0"
        right="0"
        mt="-470px"
        mr="-50px"
      />
    </Flex>
  );
}

export default ExtsyStats;
