import { Box, Button, Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import PrimeIco from "../../assets/images/prime-ico.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import WalletIcon from "../../assets/images/connect-wallet-ico.svg";

function WalletPlan() {
  return (
    <Flex gap="24px" w="100%">
      <Flex
        gap="40px"
        p="32px"
        flexDir="column"
        borderRadius="24px"
        bg="extsy.neutral900"
        border="1px solid"
        borderColor="extsy.neutral900"
        w="100%"
        pos="relative"
        overflow="hidden"
      >
        <CustomText
          text="Subscription Level"
          size="24px"
          lh="34px"
          weight="700"
          family="Titillium Web"
          color="extsy.white"
        />
        <Flex align="center" justify="space-between" gap="24px" zIndex="100">
          <CustomText
            text="Your Current Plan:"
            size="18px"
            color="extsy.baseWhite"
          />
          <Flex gap="16px" align="center">
            <Flex
              cursor="pointer"
              bg="rgba(250, 250, 250, 0.10)"
              align="center"
              gap="12px"
              px="16px"
              h="43px"
              borderRadius="999px"
            >
              <Image
                src={PrimeIco}
                alt="prime"
                height="22.5px"
                width="26.4px"
              />
              <CustomText
                text="Prime"
                color="extsy.baseWhite"
                size="22px"
                cursor="pointer"
              />
            </Flex>
            <CustomText
              text={
                <>
                  <span>$0.00</span>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#BAB8B8",
                    }}
                  >
                    /month
                  </span>
                </>
              }
              color="extsy.baseWhite"
              size="24px"
              weight="600"
            />
          </Flex>
        </Flex>
        <CustomText text="To upgrade your level, please verify your identity" />
        <Flex align="center" gap="40px" zIndex="100">
          <PrimaryButton title="Upgrade to Supreme" size="14px" maxW="216px" />
          <Flex flexDir="column" gap="4px">
            <CustomText text="for" color="extsy.baseWhite" />
            <Flex align="center" gap="4px">
              <CustomText
                text="$15.00"
                color="extsy.baseWhite"
                size="24px"
                weight="600"
              />
              <CustomText text="/month" size="14px" weight="600" />
            </Flex>
          </Flex>
        </Flex>
        <Box
          h="447px"
          w="447px"
          borderRadius="447px"
          opacity="0.5"
          bg="extsy.primary500"
          filter="blur(82.5px)"
          pos="absolute"
          bottom="0"
          right="0"
          mr="-258px"
          mb="-303px"
          zIndex="1"
        />
      </Flex>
      <Flex
        gap="40px"
        p="32px"
        flexDir="column"
        borderRadius="24px"
        bg="extsy.neutral900"
        border="1px solid"
        borderColor="extsy.neutral900"
        w="100%"
        pos="relative"
        overflow="hidden"
      >
        <Box zIndex="100">
          <CustomText
            text="Wallet"
            size="24px"
            lh="34px"
            weight="700"
            family="Titillium Web"
            color="extsy.white"
          />
        </Box>
        <Flex
          flexDir="column"
          justify="space-between"
          gap="24px"
          zIndex="100"
          h="100%"
        >
          <Flex flexDir="column" gap="12px" maxW="483px">
            <CustomText
              text="Web3 Wallet"
              size="18px"
              weight="600"
              color="extsy.baseWhite"
            />
            <CustomText text="Link your wallet to account to participate in airdrops and get more from EXTSY!" />
          </Flex>
          <Button
            bg="extsy.g1"
            borderRadius="999px"
            height="48px"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            ps="4px"
            pe="16px"
            gap="30px"
            maxW="220px"
            _hover={{ bg: "extsy.g1" }}
          >
            <Image src={WalletIcon} alt="wallet" />
            <CustomText
              text={"Connect Wallet"}
              size="14px"
              weight="600"
              family="Titillium Web"
              color="extsy.bg"
              cursor="pointer"
              ls="0.28px"
            />
          </Button>
        </Flex>

        <Box
          h="447px"
          w="447px"
          borderRadius="447px"
          opacity="0.5"
          bg="extsy.neutral700"
          filter="blur(82.5px)"
          pos="absolute"
          bottom="0"
          left="0"
          ml="-22px"
          mb="-73px"
          zIndex="1"
        />
      </Flex>
    </Flex>
  );
}

export default WalletPlan;
