import { Box, Flex, ListItem, UnorderedList } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import { useTranslation } from "react-i18next";
import SwapCard from "../../components/cards/SwapCard";

function Swap() {
  const { t } = useTranslation();
  return (
    <Flex flexDir="column" gap="32px">
      <Box
        p="24px"
        borderRadius="24px"
        border="1px solid"
        borderColor="extsy.neutral1000"
        bg="extsy.neutral1000"
      >
        <SwapCard swap />
      </Box>
      <Box
        p="32px"
        borderRadius="24px"
        border="1px solid"
        borderColor="extsy.neutral1000"
        bg="extsy.neutral1000"
      >
        <CustomText
          text={t("usefulTips")}
          size="24px"
          weight="700"
          lh="24px"
          family="Titillium Web"
          color="extsy.white"
        />
        <UnorderedList mt="32px">
          <ListItem
            fontSize="18px"
            fontFamily="Metropolis"
            color="extsy.neutral300"
            fontWeight="400"
          >
            <span
              style={{
                color: "#fff",
                fontWeight: 700,
              }}
            >
              {t("checkExchangeRate")}{" "}
            </span>
            {t("checkExchangeRateDetail")}
          </ListItem>
          <ListItem
            fontSize="18px"
            fontFamily="Metropolis"
            color="extsy.neutral300"
            fontWeight="400"
          >
            <span
              style={{
                color: "#fff",
                fontWeight: 700,
              }}
            >
              {t("considerNetworkFee")}{" "}
            </span>
            {t("considerNetworkFeeDetail")}
          </ListItem>
          <ListItem
            fontSize="18px"
            fontFamily="Metropolis"
            color="extsy.neutral300"
            fontWeight="400"
          >
            <span
              style={{
                color: "#fff",
                fontWeight: 700,
              }}
            >
              {t("doubleCheckWalletAddress")}{" "}
            </span>
            {t("doubleCheckWalletAddressDetail")}
          </ListItem>
          <ListItem
            fontSize="18px"
            fontFamily="Metropolis"
            color="extsy.neutral300"
            fontWeight="400"
          >
            <span
              style={{
                color: "#fff",
                fontWeight: 700,
              }}
            >
              {t("beAwareOfSwapTime")}{" "}
            </span>
            {t("beAwareOfSwapTimeDetail")}
          </ListItem>
          <ListItem
            fontSize="18px"
            fontFamily="Metropolis"
            color="extsy.neutral300"
            fontWeight="400"
          >
            <span
              style={{
                color: "#fff",
                fontWeight: 700,
              }}
            >
              {t("mindRateExpiration")}{" "}
            </span>
            {t("mindRateExpirationDetail")}
          </ListItem>
          <ListItem
            fontSize="18px"
            fontFamily="Metropolis"
            color="extsy.neutral300"
            fontWeight="400"
          >
            <span
              style={{
                color: "#fff",
                fontWeight: 700,
              }}
            >
              {t("useCorrectContractAddress")}{" "}
            </span>
            {t("useCorrectContractAddressDetail")}
          </ListItem>
        </UnorderedList>
        <CustomText
          text={t("howToCancelSwap")}
          size="24px"
          weight="700"
          lh="24px"
          family="Titillium Web"
          color="extsy.white"
          mt="48px"
        />
        <UnorderedList mt="24px">
          <ListItem
            fontSize="18px"
            fontFamily="Metropolis"
            color="extsy.neutral300"
            fontWeight="400"
          >
            <span
              style={{
                color: "#fff",
                fontWeight: 700,
              }}
            >
              {t("noFundsSent")}{" "}
            </span>
            {t("noFundsSentDetail")}
          </ListItem>
          <ListItem
            fontSize="18px"
            fontFamily="Metropolis"
            color="extsy.neutral300"
            fontWeight="400"
          >
            <span
              style={{
                color: "#fff",
                fontWeight: 700,
              }}
            >
              {t("fundsAlreadySent")}{" "}
            </span>
            {t("fundsAlreadySentDetail")}
          </ListItem>
        </UnorderedList>
      </Box>
    </Flex>
  );
}

export default Swap;
