import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import PrivacySecurity from "./PrivacySecurity";
import WalletPlan from "./WalletPlan";
import RaffleAffliate from "./RaffleAffliate";

function Settings() {
  const [tabIndex, setTabIndex] = useState(0);

  const TABS = [
    "Privacy & Security",
    "Wallet & Your Subscription  Plan",
    "Raffle & Affiliate",
  ];

  return (
    <Flex
      p="32px"
      flexDir="column"
      gap="40px"
      bg="extsy.neutral1000"
      border="1px solid"
      borderColor="extsy.neutral1000"
      borderRadius="24px"
    >
      <CustomText
        text="Settings"
        size="24px"
        lh="34px"
        weight="700"
        family="Titillium Web"
        color="extsy.white"
      />
      <Tabs onChange={(index) => setTabIndex(index)} defaultIndex={0}>
        <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
          {TABS?.map((tab, index) => (
            <Tab
              key={index}
              px="32px"
              pt="10px"
              pb="14px"
              color="extsy.secondary100"
            >
              <CustomText
                text={tab}
                color={
                  index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                }
                cursor="pointer"
              />
            </Tab>
          ))}
        </TabList>
        <TabPanels mt="40px">
          <TabPanel p="0">
            <PrivacySecurity />
          </TabPanel>
          <TabPanel p="0">
            <WalletPlan />
          </TabPanel>
          <TabPanel p="0">
            <RaffleAffliate />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default Settings;
