import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";
import TradingBg from "../../assets/images/trading-bg.svg";
import PrimaryButton from "../button/PrimaryButton";
import CustomButton from "../button/CustomButton";
import {
  IconArrowDownLeft,
  IconArrowRight,
  IconArrowUpRight,
} from "@tabler/icons-react";

function TradingEstimateBalanceCard() {
  return (
    <Flex
      justify="space-between"
      align="center"
      overflow="hidden"
      p="40px"
      borderRadius="24px"
      bg="extsy.neutral1000"
      pos="relative"
    >
      <Flex flexDir="column" gap="23px" zIndex="1000">
        <CustomText text="Estimated Balance" color="extsy.baseWhite" />
        <Flex gap="12px" align="flex-end">
          <CustomText
            text="20.0400799"
            size="56px"
            family="Titillium Web"
            color="extsy.secondary100"
            ls="2.8px"
          />
          <CustomText
            text="BTC"
            size="24px"
            color="extsy.neutral500"
            family="Titillium Web"
            ls="1.2px"
            lh="34px"
          />
        </Flex>
        <Flex gap="10px" align="flex-end">
          <CustomText
            text="Today’s PnL"
            family="Titillium Web"
            color="extsy.baseWhite"
            lh="22px"
          />
          <CustomText
            text="+$0.00(0.17%)"
            color="#0ECB81"
            family="Titillium Web"
            lh="22px"
          />
        </Flex>
      </Flex>
      <Flex gap="16px" align="center" w="100%" zIndex="1000" justify="flex-end">
        <PrimaryButton
          title="Deposit"
          maxW="159px"
          icon={<IconArrowDownLeft size="24px" color="#0B0A0A" />}
        />
        <CustomButton
          title="Withdraw"
          maxW="174px"
          icon={<IconArrowUpRight size="24px" color="#0B0A0A" />}
        />
        <CustomButton
          title="Transfer"
          maxW="164px"
          icon={<IconArrowRight size="24px" color="#0B0A0A" />}
        />
      </Flex>
      <Box
        height="419px"
        width="419px"
        borderRadius="419px"
        bg="#15B097"
        filter="blur(92px)"
        pos="absolute"
        zIndex="1"
        top="0"
        left="0"
        mt="-319px"
        ml="-73px"
      />
      <Image
        src={TradingBg}
        alt="bg"
        pos="absolute"
        right="0"
        filter="blur(92px)"
        zIndex="1"
      />
    </Flex>
  );
}

export default TradingEstimateBalanceCard;
