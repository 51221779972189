import { Flex, Image, Box, Switch } from "@chakra-ui/react";
import React from "react";
import NoCardIco from "../../assets/images/no-card-ico.svg";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../button/PrimaryButton";
import ForwardIcon from "../../assets/images/dashboard/forwadIcon.svg";
import BackIcon from "../../assets/images/dashboard/backIcon.svg";
import CopyIcon from "../../assets/images/dashboard/copyIcon.svg";

const DetailCard = () => {
  const { t } = useTranslation();
  return (
    <Flex
      align="center"
      gap="26px"
      flexDir="column"
      p="32px"
      borderRadius="24px"
      bg="extsy.neutral1000"
      borderWidth="1px solid"
      borderColor="extsy.neutral1000"
      //   minH={"100vh"}
    >
      <Flex justify={"space-between"} align={"center "} minW={"100%"}>
        <CustomText
          text={t("Card Detail")}
          size="24px"
          weight="700"
          color="extsy.baseWhite"
          family="Titillium Web"
        />
        <Flex gap={"5px"}>
          <Image src={BackIcon} alt="back" />
          <Image src={ForwardIcon} alt="ForwardIcon" />
        </Flex>
      </Flex>
      <Flex py={"28px"} flexDir="column" align="center">
        <Image src={NoCardIco} alt="no card" minH={"130px"} />

        <Flex flexDir="column" align="center" gap="12px" p="24px">
          <CustomText
            text={t("noCardAvailable")}
            size="18px"
            weight="600"
            color="extsy.baseWhite"
          />
          <CustomText
            text={t("applyForVirtualCard")}
            color="extsy.neutral300"
          />
        </Flex>
        <PrimaryButton title={t("orderNowForUsd")} width="272px" />
      </Flex>
      <Flex
        justify={"space-between"}
        align={"center"}
        minW={"100%"}
        borderBottom={"1px solid"}
        borderColor={"extsy.neutral900"}
        pb={"24px"}
      >
        <Flex flexDir={"column"} gap={"12px"}>
          <CustomText
            text={t("Card Number")}
            size="12px"
            color="extsy.baseWhite"
            family="Titillium Web"
          />
          <CustomText
            text={t("---- ---- ---- ----")}
            size="16px"
            color="extsy.baseWhite"
          />
        </Flex>
        <Image src={CopyIcon} alt="CopyIcon" />
      </Flex>
      <Flex
        justify={"space-between"}
        align={"center"}
        
        minW={"100%"}
        borderBottom={"1px solid"}
        borderColor={"extsy.neutral900"}
        pb={"26px"}
      >
        <Flex gap={"12px"} align={"center"} justify={"center"}>
          <CustomText text={t("CVC")} size="12px" color="extsy.neutral300" />
          <CustomText text={t("---")} size="16px" color="extsy.baseWhite" />
        </Flex>
        <Flex gap={"12px"} align={"center"}>
          <CustomText text={t("Expiry")} size="12px" color="extsy.neutral300" />
          <CustomText text={t("--/--")} size="16px" color="extsy.baseWhite" />
        </Flex>
      </Flex>
      <Flex flexDir={"column"} align={"left"} pb={"26px"} w={"100%"}  borderBottom={"1px solid"}
        borderColor={"extsy.neutral900"}>
        <CustomText
          text={t("Monthly Limits")}
          size="16px"
          weight="600"
          color="extsy.baseWhite"
        />
        <Flex flexDir={"column"} gap={"12px"} py={"18px"}>
          <CustomText
            text={t("Top-up Limit")}
            size="12px"
            color="extsy.baseWhite"
          />

          <CustomText
            text={t("$ 0 spent of $10,000")}
            
            size="12px"
            color="extsy.baseWhite"
          />
          <Box h="8px" bg={"extsy.neutral700"} borderRadius={"99px"}></Box>
        </Flex>
        <Flex flexDir={"column"} gap={"12px"}>
          <CustomText
            text={t("Trading Limit")}
            size="12px"
            color="extsy.baseWhite"
          />

          <CustomText
            text={t("$ 0 spent of $10,000")}
            size="12px"
            color="extsy.baseWhite"
          />
          <Box h="8px" bg={"extsy.neutral700"} borderRadius={"99px"}></Box>
        </Flex>
      </Flex>
      <Flex justify={"space-between"} align={"center "} minW={"100%"}>
        <CustomText
          text={t("Freeze Card")}
          size="16px"
          color="extsy.baseWhite"
        />
        <Flex gap={"5px"}>
          <Switch id="email-alerts" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DetailCard;
