import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import MineCryptoIco from "../../assets/images/mine-crypto-ico.svg";

function AvailableBalanceCard() {
  const { t } = useTranslation();

  let isConnected = localStorage.getItem("isConnected");

  return (
    <Flex
      p="40px"
      borderRadius="24px"
      bg="extsy.neutral1000"
      minH="220px"
      justify="space-between"
      flexDir="column"
      overflow="hidden"
      pos="relative"
    >
      <Box
        height="419px"
        width="419px"
        background="extsy.g1"
        opacity="0.6"
        filter="blur(92px)"
        borderRadius="419px"
        pos="absolute"
        left="0"
        top="0"
        ml="-331px"
        mt="-379px"
      />
      <CustomText text={t("availableBalance")} color="extsy.baseWhite" />
      <Flex align="flex-end" gap="12px">
        <CustomText
          text={isConnected ? "$624,000.99" : "0.00"}
          size="56px"
          family="Titillium Web"
          ls="2.8px"
          color="extsy.secondary100"
        />
        <Box pb="4px">
          <CustomText
            text={isConnected ? "USD" : t("noWalletConnected")}
            size="24px"
            ls="1.2px"
            family="Titillium Web"
            color="extsy.neutral500"
          />
        </Box>
      </Flex>
      <Image
        src={MineCryptoIco}
        alt="mine-crypto"
        pos="absolute"
        top="0"
        draggable="false"
        right="0"
        zIndex="10"
      />
      <Box
        width="430px"
        height="524px"
        bg="extsy.secondary100"
        filter="blur(197px)"
        pos="absolute"
        right="0"
        bottom="0"
        mb="-344px"
        mr="-361px"
        zIndex="1"
      />
    </Flex>
  );
}

export default AvailableBalanceCard;
