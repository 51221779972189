import { Box, Flex } from "@chakra-ui/react";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";

function CustomSelect({
  options,
  maxW,
  h,
  bg,
  radius,
  value,
  setValue,
  placeholder,
}: any) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box pos="relative">
      <Flex
        align="center"
        justify="space-between"
        maxW={maxW || "100%"}
        w="100%"
        cursor="pointer"
        p="9.5px 19.5px"
        h={h || "56px"}
        borderRadius={radius || "16px"}
        bg={bg || "extsy.neutral900"}
        onClick={() => setIsOpen(!isOpen)}
      >
        <CustomText text={value || placeholder} size="14px" cursor />
        <IconChevronDown size={24} color="#fafafa" />
      </Flex>
      <Flex
        p="16px"
        flexDir="column"
        gap="16px"
        bg="extsy.bg"
        borderRadius="8px"
        w="100%"
        pos="absolute"
        top="0"
        mt="60px"
        display={isOpen ? "flex" : "none"}
      >
        {options?.map((option: any) => (
          <Flex
            key={option.value}
            align="center"
            justify="space-between"
            cursor="pointer"
            onClick={() => {
              setIsOpen(false);
              setValue(option.value);
            }}
          >
            <CustomText
              text={option.label}
              cursor
              color={option?.value === value ? "extsy.baseWhite" : ""}
            />
            {option.value === value && <IconCheck color="#fafafa" size={20} />}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}

export default CustomSelect;
