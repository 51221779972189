import {
  Box,
  Flex,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import SwapAmountInput from "../form/SwapAmountInput";
import AmountRaising from "../../assets/images/amount-rising.svg";
import EthIco from "../../assets/images/eth-ico.svg";
import {
  IconArrowDown,
  IconArrowUp,
  IconChevronDown,
} from "@tabler/icons-react";
import PrimaryButton from "../button/PrimaryButton";
import InfoIco from "../../assets/images/info-ico.svg";
import USDIco from "../../assets/images/usd-ico.svg";
import AgreeCheckbox from "../../assets/images/agree-checkbox.svg";
import FiatCurrencyDropdown from "../form/FiatCurrencyDropdown";
import CryptoCurrencyDropdown from "../form/CryptoCurrencyDropdown";

function BuySellCard() {
  const { t } = useTranslation();

  const tabs = [t("buy"), t("sell")];

  const [activeCurrency, setActiveCurrency] = useState<any>({
    currency1: {
      symbol: "USD",
      name: "US Dollar",
      icon: USDIco,
    },
    currency2: {
      symbol: "ETH",
      name: "Ethereum",
      icon: EthIco,
      price: "$3,456.54",
    },
  });
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Box position="relative">
      <Tabs p="0" onChange={(index) => setTabIndex(index)} defaultIndex={0}>
        <TabList
          overflow="hidden"
          width="116px"
          p="2px"
          //   height="28px"
          borderRadius="4px"
          border="1px solid #000"
          bg="extsy.dark300"
          ml="auto"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              display="flex"
              alignItems="center"
              gap="4px"
              p="6px 10px"
              borderRadius="4px"
              height="24px"
              width="56px"
              bg={tabIndex === index ? "extsy.g1" : "transparent"}
              color="extsy.dark300"
            >
              <CustomText
                text={tab}
                cursor="pointer"
                size="12px"
                family="Titillium Web"
                color={tabIndex === index ? "extsy.dark300" : "extsy.white"}
              />
              {tab === "Buy" ? (
                <IconArrowDown
                  size={12}
                  color={tabIndex === index ? "#000F18" : "white"}
                />
              ) : (
                <IconArrowUp
                  size={12}
                  color={tabIndex === index ? "#000F18" : "white"}
                />
              )}
            </Tab>
          ))}
        </TabList>
        <TabPanels mt="14px" p="0">
          <TabPanel p="0" display="flex" flexDir="column" gap="10px">
            <FiatBox
              activeCurrency={activeCurrency?.currency1}
              setActiveCurrency={setActiveCurrency}
            />
            <CryptoBox
              setActiveCurrency={setActiveCurrency}
              activeCurrency={activeCurrency?.currency2}
            />
          </TabPanel>
          <TabPanel p="0" display="flex" flexDir="column" gap="10px">
            <CryptoBox
              setActiveCurrency={setActiveCurrency}
              activeCurrency={activeCurrency?.currency2}
            />
            <FiatBox
              activeCurrency={activeCurrency?.currency1}
              setActiveCurrency={setActiveCurrency}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Flex align="center" gap="6px" pl="25px" mt="6px">
        <CustomText
          text={t("comparePrices")}
          size="10px"
          family="Titillium Web"
          color="extsy.white"
          underline
          cursor="pointer"
        />
        <Image src={InfoIco} alt="info" />
      </Flex>
      <Flex my="17px" gap="8px" align="flex-start">
        <Image src={AgreeCheckbox} alt="agree" />
        <CustomText
          text={t("readAgreeTerms")}
          family="Titillium Web"
          size="12px"
          color="extsy.white"
        />
      </Flex>
      <PrimaryButton
        title={tabIndex === 0 ? t("buyNow") : t("sellNow")}
        width="100%"
        height="47px"
      />
    </Box>
  );
}

const CryptoBox = ({ activeCurrency, setActiveCurrency }: any) => {
  const { t } = useTranslation();
  const [isCurrency2Open, setIsCurrency2Open] = useState(false);
  return (
    <Box
      p="10px 19px"
      borderRadius="20.5px"
      border="0.857px solid"
      borderColor="extsy.white"
      backdropBlur="blur(5.39px)"
      pos="relative"
    >
      <Flex align="center" justify="space-between" gap="16px">
        <Flex flexDir="column" gap="9px" maxW="164px">
          <SwapAmountInput fontSize="18px" />
          <Flex align="center" gap="7px">
            <Image src={AmountRaising} alt="up" />
            <CustomText
              text="+ 3,390 (5.94%)"
              size="12px"
              family="Titillium Web"
              color="extsy.white"
            />
          </Flex>
        </Flex>
        <Flex align="center" gap="10px">
          <Flex
            gap="10px"
            align="center"
            onClick={() => {
              setIsCurrency2Open(!isCurrency2Open);
            }}
          >
            <Flex flexDir="column" gap="2px" align="flex-end" cursor="pointer">
              <Flex align="center" gap="8px">
                <IconChevronDown color="#fff" size="16px" />
                <CustomText
                  text={activeCurrency.symbol}
                  size="14px"
                  weight="700"
                  family="Titillium Web"
                  color="extsy.white"
                  cursor="pointer"
                />
              </Flex>
              <CustomText
                text={activeCurrency.name}
                size="10px"
                color="extsy.white"
                family="Titillium Web"
                cursor="pointer"
              />
            </Flex>
            <Image
              src={activeCurrency?.icon}
              alt="btc"
              cursor="pointer"
              height="24px"
              width="24px"
            />
          </Flex>
        </Flex>
      </Flex>
      {isCurrency2Open && (
        <CryptoCurrencyDropdown
          activeCurrency={activeCurrency.currency2}
          onClick={(currency: any) => {
            setActiveCurrency({
              ...activeCurrency,
              currency2: currency,
            });
            setIsCurrency2Open(false);
          }}
        />
      )}
      <Flex align="center" mt="19px" gap="10px" justify="center">
        <Flex align="center" gap="6px">
          <CustomText
            text={t("noHiddenFee")}
            size="10px"
            family="Titillium Web"
            color="extsy.white"
            underline
            cursor="pointer"
          />
          <Image src={InfoIco} alt="info" />
        </Flex>
        <Flex align="center" gap="6px">
          <CustomText
            text={t("estimatedRate")}
            size="10px"
            family="Titillium Web"
            color="extsy.white"
            underline
            cursor="pointer"
          />
          <Image src={InfoIco} alt="info" />
        </Flex>
        <CustomText
          text="1 BTC ~ 22.22413 ETH"
          size="10px"
          family="Titillium Web"
          color="extsy.white"
        />
      </Flex>
    </Box>
  );
};

const FiatBox = ({ activeCurrency, setActiveCurrency }: any) => {
  const [isCurrency1Open, setIsCurrency1Open] = useState(false);
  return (
    <Box
      p="10px 19px"
      borderRadius="20.5px"
      border="0.857px solid"
      borderColor="extsy.white"
      backdropBlur="blur(5.39px)"
      pos="relative"
    >
      <Flex align="center" justify="space-between" gap="16px">
        <Flex flexDir="column" gap="9px" maxW="164px">
          <SwapAmountInput noBorder fontSize="18px" />
        </Flex>
        <Flex align="center" gap="10px">
          <Flex
            align="center"
            gap="10px"
            cursor="pointer"
            onClick={() => {
              setIsCurrency1Open(!isCurrency1Open);
            }}
          >
            <Flex flexDir="column" gap="2px" align="flex-end">
              <Flex align="center" gap="8px">
                <IconChevronDown color="#fff" size="16px" />
                <CustomText
                  text={activeCurrency.symbol}
                  size="14px"
                  weight="700"
                  family="Titillium Web"
                  color="extsy.white"
                  cursor="pointer"
                />
              </Flex>
            </Flex>
            <Image
              src={activeCurrency?.icon}
              alt="eth"
              cursor="pointer"
              height="24px"
              width="24px"
            />
          </Flex>
        </Flex>
      </Flex>
      {isCurrency1Open && (
        <FiatCurrencyDropdown
          activeCurrency={activeCurrency.currency1}
          onClick={(currency: any) => {
            setActiveCurrency({
              ...activeCurrency,
              currency1: currency,
            });
            setIsCurrency1Open(false);
          }}
        />
      )}
    </Box>
  );
};

export default BuySellCard;
