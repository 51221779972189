import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import TableSearchInput from "../../components/form/TableSearchInput";
import PrimaryButton from "../../components/button/PrimaryButton";
import { IconCalendar } from "@tabler/icons-react";
import EmptyView from "./EmptyView";
import HistoryTable from "./HistoryTable";
import SwapCard from "../../components/cards/SwapCard";
import CustomCalendar from "../../components/form/CustomCalendar";

function TransactionHistory() {
  const [tabIndex, setTabIndex] = useState(0);
  const [date, setDate] = useState("10-08-2024");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const TABS = [
    "Swap",
    "Deposit",
    "Withdraw",
    "Raffle",
    "Subscription Payments",
  ];

  const formatedDate = () => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
  };

  return (
    <Flex flexDir="column" gap="24px">
      <Box
        p="24px"
        borderRadius="24px"
        bg="extsy.neutral1000"
        boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
      >
        <Flex align="center" justify="space-between" pb="20px">
          <CustomText
            text="Transaction History"
            size="24px"
            lh="34px"
            family="Titillium Web"
            weight="700"
            color="extsy.baseWhite"
          />
          <Flex w="80%" justify="end" gap="10px" align="center">
            <TableSearchInput placeholder="Search Transaction" />
            <Box pos="relative">
              <Flex
                cursor="pointer"
                gap="16px"
                align="center"
                borderRadius="666px"
                bg="extsy.neutral900"
                boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                height="38px"
                ps="24px"
                pe="10px"
                onClick={() => setIsCalendarOpen(!isCalendarOpen)}
              >
                <CustomText
                  cursor
                  text={formatedDate()}
                  size="12px"
                  color="extsy.baseWhite"
                />
                <IconCalendar size="16px" color="#fafafa" />
              </Flex>
              <CustomCalendar
                isCalendarOpen={isCalendarOpen}
                value={date}
                onChange={(val: any) => {
                  setDate(val);
                  setIsCalendarOpen(false);
                }}
              />
            </Box>
            <PrimaryButton
              title="Export PDF"
              size="14px"
              color="extsy.bg"
              height="38px"
              maxW="134px"
            />
          </Flex>
        </Flex>
        <Tabs onChange={(index) => setTabIndex(index)} defaultIndex={0}>
          <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
            {TABS?.map((tab, index) => (
              <Tab
                key={index}
                px="32px"
                pt="10px"
                pb="14px"
                color="extsy.secondary100"
              >
                <CustomText
                  text={tab}
                  color={
                    index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                  }
                  cursor="pointer"
                />
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel px="0px" pt="24px">
              <HistoryTable />
            </TabPanel>
            <TabPanel px="0px" pt="24px">
              <EmptyView tagline="No Transaction History Available" />
            </TabPanel>
            <TabPanel px="0px" pt="24px">
              <EmptyView tagline="No Transaction History Available" />
            </TabPanel>
            <TabPanel px="0px" pt="24px">
              <EmptyView tagline="No Transaction History Available" />
            </TabPanel>
            <TabPanel px="0px" pt="24px">
              <EmptyView tagline="No Transaction History Available" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Box
        p="24px"
        borderRadius="24px"
        bg="extsy.neutral1000"
        boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
      >
        <SwapCard swap />
      </Box>
    </Flex>
  );
}

export default TransactionHistory;
