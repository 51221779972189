import React from "react";
import CustomText from "../text/CustomText";
import { Flex, Image } from "@chakra-ui/react";
import EmptyTrx from "../../assets/images/empty-trx-ico.svg";

function TradeHistory() {
  return (
    <Flex
      minH="150px"
      flexDir="column"
      gap="16px"
      align="center"
      justify="center"
    >
      <Image src={EmptyTrx} alt="empty-trx" />
      <CustomText
        text="You have no trade history"
        size="16px"
        color="extsy.neutral400"
      />
    </Flex>
  );
}

export default TradeHistory;
