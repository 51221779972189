import { Box, Flex, Image, Tooltip, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import LogoText from "../../assets/images/extsy-logo.svg";
import Logo from "../../assets/images/logo-icon.svg";
import ArrowCollapseIco from "../../assets/images/collapse-ico.svg";
import CollapseOpenIco from "../../assets/images/open-collapse-ico.svg";
import DashboardActive from "../../assets/images/dashboard/dashboard-active.svg";
import DashboardInactive from "../../assets/images/dashboard/dashboard-inactive.svg";
import SwapInactive from "../../assets/images/dashboard/swap-inactive.svg";
import SwapActive from "../../assets/images/dashboard/swap-active.svg";
import VirualCardInactive from "../../assets/images/dashboard/virtual-inactive.svg";
import VirtualCardActive from "../../assets/images/dashboard/virtual-active.svg";
import TradingInactive from "../../assets/images/dashboard/trading-inactive.svg";
import TradingActive from "../../assets/images/dashboard/trading-active.svg";
import TradingBalanceInactive from "../../assets/images/dashboard/trading-balance-inactive.svg";
import TradingBalanceActive from "../../assets/images/dashboard/trading-balance-active.svg";
import BalanceTrackerInactive from "../../assets/images/dashboard/balance-tracker-inactive.svg";
import BalanceTrackerActive from "../../assets/images/dashboard/balance-tracker-active.svg";
import ExtsyProInactive from "../../assets/images/dashboard/extsy-pro-inactive.svg";
import ExtsyProActive from "../../assets/images/dashboard/extsy-pro.svg";
import TransactionHistoryInactive from "../../assets/images/dashboard/transaction-history-inactive.svg";
import TransactionHistoryActive from "../../assets/images/dashboard/transaction-history-active.svg";
import AMLCheckInactive from "../../assets/images/dashboard/aml-check-inactive.svg";
import AMLCheckActive from "../../assets/images/dashboard/aml-check-ative.svg";
import RaffleInactive from "../../assets/images/dashboard/raffle-inactive.svg";
import RaffleActive from "../../assets/images/dashboard/raffle-active.svg";
import P2PInactive from "../../assets/images/dashboard/p2p-inactive.svg";
import P2PActive from "../../assets/images/dashboard/p2p-active.svg";
import SettingsActive from "../../assets/images/dashboard/settings-active.svg";
import SettingsInactive from "../../assets/images/dashboard/settings-inactive.svg";
import ChatInactive from "../../assets/images/dashboard/chat-inative.svg";
import LogoutIco from "../../assets/images/dashboard/logout-ico.svg";

import CustomText from "../text/CustomText";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomModal from "../modal/customModal";

function Sidebar() {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    isOpen: isLogoutOpen,
    onOpen: onLogoutOpen,
    onClose: onLogoutClose,
  } = useDisclosure();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const DASHBOARD_MENU = [
    {
      path: "/",
      name: t("dashboard"),
      activeIco: DashboardActive,
      inActiveIco: DashboardInactive,
    },
    {
      path: "/swap",
      name: t("swap"),
      activeIco: SwapActive,
      inActiveIco: SwapInactive,
    },
    {
      path: "/virtual-card",
      name: t("virtualCard"),
      activeIco: VirtualCardActive,
      inActiveIco: VirualCardInactive,
    },
    {
      path: "/trading",
      name: t("trading"),
      activeIco: TradingActive,
      inActiveIco: TradingInactive,
    },
    {
      path: "/trading-balance",
      name: t("tradingBalance"),
      activeIco: TradingBalanceActive,
      inActiveIco: TradingBalanceInactive,
    },
    {
      path: "/balance-tracker",
      name: t("balanceTracker"),
      activeIco: BalanceTrackerActive,
      inActiveIco: BalanceTrackerInactive,
    },
    {
      path: "/extsy-pro",
      name: t("extsyPro"),
      activeIco: ExtsyProActive,
      inActiveIco: ExtsyProInactive,
    },
    {
      path: "/transaction-history",
      name: t("transactionHistory"),
      activeIco: TransactionHistoryActive,
      inActiveIco: TransactionHistoryInactive,
    },
    {
      path: "/aml-check",
      name: t("amlCheck"),
      activeIco: AMLCheckActive,
      inActiveIco: AMLCheckInactive,
    },
    {
      path: "/raffle",
      name: t("raffle"),
      activeIco: RaffleActive,
      inActiveIco: RaffleInactive,
    },
    {
      path: "/p2p",
      name: t("p2p"),
      activeIco: P2PActive,
      inActiveIco: P2PInactive,
    },
  ];

  const DASHBOARD_OTHER_MENU = [
    {
      path: "/settings",
      name: t("settings"),
      activeIco: SettingsActive,
      inActiveIco: SettingsInactive,
    },
    {
      path: "/chat",
      name: t("chat"),
      activeIco: "",
      inActiveIco: ChatInactive,
    },
    {
      name: t("logout"),
      activeIco: LogoutIco,
    },
  ];
  const handleNavigate = (path: any) => {
    navigate(path);
  };

  return (
    <Flex
      p="24px"
      bg="extsy.neutral1000"
      w={isCollapsed ? "92px" : "280px"}
      borderRadius="24px"
      h="calc(100vh - 48px)"
      alignItems="flex-start"
      transition="width 0.3s"
      flexDir="column"
    >
      <Flex justify="space-between" align="center" w="100%">
        <Flex gap="6px" cursor="pointer">
          <Image src={Logo} alt="logo" width={"36px"} height={"26px"} />
          {!isCollapsed && (
            <Image src={LogoText} alt="logo" width="71px" height="25px" />
          )}
        </Flex>
        <Flex
          cursor="pointer"
          height="30px"
          width="30px"
          bg="extsy.text"
          borderRadius="8px"
          align="center"
          justify="center"
          marginRight="-38px"
          userSelect="none"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <Image
            src={isCollapsed ? CollapseOpenIco : ArrowCollapseIco}
            alt="collapse"
          />
        </Flex>
      </Flex>
      <Flex
        mt="40px"
        flexDir="column"
        justify="space-between"
        gap="8px"
        overflowY="auto"
        h="100%"
        __css={{
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            bg: "extsy.neutral900",
            borderRadius: "8px",
          },
        }}
      >
        <Box>
          <CustomText
            text={t("menu")}
            color="extsy.neutral200"
            size="12px"
            weight="600"
            ls="0.12px"
            align={isCollapsed ? "center" : "start"}
          />
          <Flex flexDir="column" gap="8px" mt="8px" width="100%">
            {DASHBOARD_MENU.map((item, index) => (
              <Tooltip
                label={item?.name}
                display={isCollapsed ? "flex" : "none"}
                placement="right"
                ml="10px"
                alignItems="center"
                justifyContent="center"
                borderRadius="8px"
                bg="extsy.g1"
                p="8px 24px"
                color="extsy.bg"
                fontFamily="Metropolis"
                fontSize="16px"
                fontWeight="400"
              >
                <Flex
                  key={index}
                  align="center"
                  justify={isCollapsed ? "center" : "flex-start"}
                  cursor="pointer"
                  p={isCollapsed ? "0px" : "12px"}
                  height="44px"
                  width={isCollapsed ? "44px" : "232px"}
                  gap="8px"
                  borderRadius="8px"
                  bg={item.path === pathname ? "extsy.g2" : "transparent"}
                  onClick={() => handleNavigate(item?.path)}
                >
                  <Image
                    src={
                      pathname === item?.path
                        ? item.activeIco
                        : item.inActiveIco
                    }
                    alt={item?.name}
                    width="24px"
                    height="24px"
                  />
                  {!isCollapsed && (
                    <CustomText
                      text={item.name}
                      color={
                        item?.path === pathname
                          ? "extsy.bg"
                          : "extsy.neutral300"
                      }
                      cursor="pointer"
                    />
                  )}
                </Flex>
              </Tooltip>
            ))}
          </Flex>
        </Box>
        <Box>
          <CustomText
            text={t("others")}
            mt="20px"
            color="extsy.neutral200"
            size="12px"
            weight="600"
            ls="0.12px"
            align={isCollapsed ? "center" : "start"}
          />
          <Flex flexDir="column" gap="8px" mt="8px" width="100%">
            {DASHBOARD_OTHER_MENU.map((item, index) => (
              <Tooltip
                label={item?.name}
                display={isCollapsed ? "flex" : "none"}
                placement="right"
                ml="10px"
                alignItems="center"
                justifyContent="center"
                borderRadius="8px"
                bg="extsy.g1"
                p="8px 24px"
                color="extsy.bg"
                fontFamily="Metropolis"
                fontSize="16px"
                fontWeight="400"
              >
                <Flex
                  key={index}
                  align="center"
                  justify={isCollapsed ? "center" : "flex-start"}
                  cursor="pointer"
                  p={isCollapsed ? "0px" : "12px"}
                  height="44px"
                  width={isCollapsed ? "44px" : "232px"}
                  gap="8px"
                  borderRadius="8px"
                  bg={item.path === pathname ? "extsy.g2" : "transparent"}
                  onClick={() => {
                    if (item?.name === "Logout") {
                      onLogoutOpen();
                    } else {
                      handleNavigate(item?.path);
                    }
                  }}
                >
                  <Image
                    src={
                      item?.name === "Logout"
                        ? item?.activeIco
                        : pathname === item?.path
                        ? item.activeIco
                        : item.inActiveIco
                    }
                    alt={item?.name}
                    width="24px"
                    height="24px"
                  />
                  {!isCollapsed && (
                    <CustomText
                      text={item.name}
                      color={
                        item?.path === pathname
                          ? "extsy.bg"
                          : "extsy.neutral300"
                      }
                      cursor="pointer"
                    />
                  )}
                </Flex>
              </Tooltip>
            ))}
          </Flex>
        </Box>
      </Flex>
      <CustomModal
        isOpen={isLogoutOpen}
        onClose={onLogoutClose}
        onSubmit={() => {
          onLogoutClose();
          navigate("/auth/login");
        }}
        headerText={"Logout"}
        btnText={"Confirm, Logout"}
        closeBtntext={"Cancel"}
        width={"600px"}
        bgtopBlur="extsy.primary"
      >
        <Flex p="32px" flexDir="column" align="center">
          <CustomText text="Are you sure you want to end this session?" />
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default Sidebar;
