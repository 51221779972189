import {
  Avatar,
  Box,
  Circle,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Input,
  Step,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
  useDisclosure,
  useSteps,
} from "@chakra-ui/react";
import {
  IconChecks,
  IconInfoCircleFilled,
  IconPhotoPlus,
  IconSend,
  IconX,
} from "@tabler/icons-react";
import React, { useState } from "react";
import CustomText from "../../../components/text/CustomText";
import ExtsyGuard from "../../../assets/images/extsy-guard.svg";
import GradientText from "../../../components/text/GradientText";
import CustomButton from "../../../components/button/CustomButton";
import PrimaryButton from "../../../components/button/PrimaryButton";
import ActiveStepperIcon from "../../../assets/images/stepper-active-circle.svg";
import { ORDER_MESSAGES } from "../../../utils/constants";
import CustomModal from "../../../components/modal/customModal";
import IconCheckMark from "../../../assets/images/agree-checkbox.svg";

function OrderDetails({ isOpen, onClose }: any) {
  const { activeStep } = useSteps({
    index: 2,
    count: 3,
  });
  const {
    isOpen: isCancelOrderOpen,
    onOpen: onOpenCancelOrder,
    onClose: onCloseCancelOrder,
  } = useDisclosure();

  const [messages, setMessages] = useState<any>(ORDER_MESSAGES);
  const [message, setMessage] = useState("");
  const [selectedReason, setSelectedReason] = useState("");

  const BUYER_REASONS = [
    "I do not want to trade anymore.",
    "I do not meet the requirements of the advertiser’s trading terms and condition",
    "There is technical error with the payment platform",
    "I have not paid but clicked ‘Transfered’",
    "Other reason",
  ];
  const SELLER_REASONS = [
    "Seller is asking for extra fee",
    "Problem with seller’s payment method result in unsuccessful payments.",
  ];

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg="extsy.neutral1000" p="24px" maxW="1045px" w="100%">
          <Flex flexDir="column" gap="16px" h="100%">
            <Flex h="32px" align="center">
              <IconX
                size="24px"
                color="#FAFAFA"
                cursor="pointer"
                onClick={onClose}
              />
            </Flex>
            <Flex gap="24px" h="100%">
              <Flex
                p="25px"
                bg="extsy.neutral900"
                borderRadius="16px"
                boxShadow="0px 2px 10px 0px rgba(0, 0, 0, 0.10)"
                maxW="425px"
                w="100%"
                h="100%"
                flexDir="column"
                justify="space-between"
                gap="12px"
              >
                <Box>
                  <CustomText
                    text="Order Details"
                    size="24px"
                    weight="600"
                    color="extsy.baseWhite"
                  />
                  <Flex flexDir="column" gap="15px" mt="15px" py="25px">
                    <Flex align="center" gap="8px">
                      <CustomText
                        text="Order Created, Pay the seller with in"
                        weight="600"
                        color="extsy.baseWhite"
                      />
                      <GradientText text="15:00" weight="600" bg="extsy.g1" />
                    </Flex>
                    <Flex align="flex-start" gap="15px">
                      <Image
                        src={ExtsyGuard}
                        alt="extsy-guard"
                        w="15px"
                        h="20px"
                      />
                      <CustomText
                        text="Extsy is holding the seller’s crpto in the escrow account"
                        weight="600"
                        size="14px"
                      />
                    </Flex>
                    <Flex align="center" gap="15px">
                      <Image
                        src={ExtsyGuard}
                        alt="extsy-guard"
                        w="15px"
                        h="20px"
                      />
                      <CustomText
                        text="Extsy 24/7 customer support"
                        weight="600"
                        size="14px"
                      />
                    </Flex>
                  </Flex>
                  <Flex mt="25px" py="25px">
                    <Stepper
                      index={activeStep}
                      orientation="vertical"
                      gap="0"
                      w="100%"
                      maxW="375px"
                    >
                      <Step key={1}>
                        <StepIndicator
                          zIndex="10"
                          sx={{
                            "[data-status=complete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=active] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=incomplete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                          }}
                        >
                          <StepStatus
                            complete={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                            incomplete={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                            active={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                          />
                        </StepIndicator>

                        <Box flexShrink="0" mt="10px" minW="339px" mb="25px">
                          <CustomText
                            text="Order Created"
                            weight="600"
                            color="extsy.baseWhite"
                          />
                          <Flex
                            p="16px"
                            borderRadius="16px"
                            border="1px solid"
                            borderColor="extsy.neutral800"
                            mt="15px"
                            flexDir="column"
                            gap="15px"
                          >
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Fiat Amount"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text="$ 4000"
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Amount"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text="2654 USDT"
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Provider"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text="Cybil"
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                          </Flex>
                        </Box>

                        <StepSeparator
                          style={{
                            width: 0,
                            border: "1px solid",
                            borderColor: "#F0C2DB",
                            top: 0,
                            maxHeight: "100%",
                            marginTop: "10px",
                            background: "transparent",
                          }}
                        />
                      </Step>
                      <Step key={2}>
                        <StepIndicator
                          sx={{
                            "[data-status=complete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=active] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=incomplete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                          }}
                          zIndex="10"
                        >
                          <StepStatus
                            complete={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                            incomplete={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                            active={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                          />
                        </StepIndicator>
                        <Box flexShrink="0" mt="10px" minW="339px" mb="25px">
                          <CustomText
                            text="Payment Method: Bank"
                            weight="600"
                            color="extsy.baseWhite"
                          />
                          <CustomText
                            text="Transfer the funds to the seller’s account provided below."
                            mt="8px"
                            family="Titillium Web"
                            size="12px"
                          />
                          <Flex
                            p="16px"
                            borderRadius="16px"
                            border="1px solid"
                            borderColor="extsy.neutral800"
                            mt="15px"
                            flexDir="column"
                            gap="15px"
                          >
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Full Name"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text="Luis Pasture"
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Bank"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text="Bank of America"
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Bank Account"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text="09479484892065"
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Bank of Deposit"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text="Bank of America"
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                          </Flex>
                        </Box>
                        <StepSeparator
                          style={{
                            width: 0,
                            border: "1px dashed",
                            borderColor: "#908B8B",
                            top: 0,
                            maxHeight: "100%",
                            marginTop: "10px",
                            background: "transparent",
                          }}
                        />{" "}
                      </Step>
                      <Step key={3}>
                        <StepIndicator
                          sx={{
                            "[data-status=complete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=active] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=incomplete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                          }}
                        >
                          <StepStatus
                            complete={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                            incomplete={<Circle bg="#626262" size="20px" />}
                            active={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                          />
                        </StepIndicator>

                        <Box flexShrink="0" mt="10px" minW="339px">
                          <CustomText
                            text="Notify Seller"
                            weight="600"
                            color="extsy.baseWhite"
                          />
                          <Box mt="15px" maxW="339px">
                            <CustomText
                              text="After payment, remember to click the ‘Transferred, Notify Seller’ button to facilitate the crypto release by the seller."
                              family="Titillium Web"
                              size="12px"
                            />
                          </Box>
                        </Box>
                      </Step>
                    </Stepper>
                  </Flex>
                </Box>
                <Flex align="center" gap="16px">
                  <CustomButton
                    size="14px"
                    color="extsy.baseWhite"
                    bg="extsy.neutral800"
                    title="Cancel Order"
                    height="48px"
                    onClick={onOpenCancelOrder}
                  />
                  <PrimaryButton
                    title="Mark As Paid"
                    size="14px"
                    height="48px"
                    onClick={onClose}
                  />
                </Flex>
              </Flex>
              <Box w="1px" bg="extsy.bg" />
              <Flex
                flexDir="column"
                w="100%"
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor="extsy.neutral800"
                borderRadius="16px"
              >
                <Flex
                  p="16px 24px"
                  bg="extsy.neutral800"
                  borderRadius="8px 8px 0px 0px"
                  align="center"
                  gap="16px"
                >
                  <Box pos="relative">
                    <Avatar boxSize="50px" />
                    <Circle
                      size="20px"
                      bg="#15B097"
                      border="3px solid"
                      borderColor="extsy.neutral800"
                      pos="absolute"
                      right="0"
                      bottom="0"
                      mr="-5px"
                      mb="-2px"
                    />
                  </Box>
                  <Flex flexDir="column" gap="4px">
                    <CustomText
                      text="John Doe"
                      size="25px"
                      weight="500"
                      color="extsy.baseWhite"
                    />
                    <CustomText text="Online" color="extsy.baseWhite" />
                  </Flex>
                </Flex>
                <Flex
                  p="16px 16px 30px 16px"
                  h="100%"
                  flexDir="column"
                  gap="16px"
                  maxH="72.5vh"
                  overflowY="auto"
                >
                  {messages?.map((message: any, index: number) => (
                    <Flex
                      key={index}
                      gap="16px"
                      justify={message?.isMe ? "flex-end" : "flex-start"}
                      align={message?.attachment ? "flex-end" : "flex-start"}
                    >
                      <Avatar
                        boxSize="40px"
                        order={message?.isMe ? "1" : "0"}
                        mb="25px"
                      />
                      <Flex
                        flexDir="column"
                        gap="8px"
                        order={message?.isMe ? "0" : "1"}
                        maxW="380px"
                        w="100%"
                      >
                        {message?.attachment ? (
                          <Image
                            src={message?.attachment}
                            alt="attachemtn"
                            height="228px"
                            width="200px"
                            ml={message?.isMe ? "auto" : ""}
                          />
                        ) : (
                          <Box
                            p="16px"
                            bg="extsy.neutral900"
                            borderRadius={
                              message?.isMe
                                ? "16px 16px 0px 16px"
                                : "0px 16px 16px 16px"
                            }
                            w="100%"
                          >
                            <CustomText
                              text={message.message}
                              size="14px"
                              color="extsy.baseWhite"
                            />
                          </Box>
                        )}
                        <Flex
                          align="center"
                          gap="8px"
                          justify={message?.isMe ? "end" : "start"}
                        >
                          <CustomText
                            text={message?.time}
                            size="12.8px"
                            color="extsy.neutral500"
                          />
                          {message?.isMe && (
                            <IconChecks
                              color={message?.isRead ? "#E35CAD" : "#BAB8B8"}
                              size="16px"
                            />
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  ))}
                  <Box
                    bg="extsy.neutral900"
                    borderRadius="16px"
                    p="16px 24px"
                    maxW="470px"
                    mx="auto"
                  >
                    <CustomText
                      text="You have marked the order as paid, please wait for seller to confirm and release the asset."
                      size="14px"
                      family="Titillium Web"
                      align="center"
                    />
                  </Box>
                  <Box
                    bg="extsy.neutral900"
                    borderRadius="16px"
                    p="16px 24px"
                    maxW="470px"
                    mx="auto"
                  >
                    <CustomText
                      text="The order has been cancelled. Please contract customer support if you have any questions."
                      size="14px"
                      family="Titillium Web"
                      align="center"
                    />
                  </Box>
                </Flex>
                <Flex
                  p="16px"
                  cursor="pointer"
                  borderRadius="16px"
                  bg="extsy.neutral800"
                  justify="space-between"
                  align="center"
                  gap="16px"
                  h="93px"
                  zIndex="100"
                >
                  <Input
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    type="text"
                    fontSize="16px"
                    fontWeight="400"
                    fontFamily="Metropolis"
                    color="extsy.baseWhite"
                    placeholder="Type your message..."
                    pl="0"
                    border="none"
                    _placeholder={{ color: "extsy.neutral500" }}
                    _hover={{
                      border: "none",
                    }}
                    _focus={{
                      border: "none",
                    }}
                    _focusVisible={{ border: "none" }}
                  />
                  <Flex gap="16px" align="center">
                    <Flex
                      align="center"
                      justify="center"
                      height="36px"
                      width="36px"
                      bg="extsy.neutral700"
                      borderRadius="8px"
                      cursor="pointer"
                    >
                      <IconPhotoPlus size="19px" color={"#FAFAFA"} />
                    </Flex>
                    <Flex
                      align="center"
                      justify="center"
                      height="36px"
                      width="36px"
                      bg="extsy.g1"
                      borderRadius="8px"
                      cursor="pointer"
                      onClick={() => {
                        setMessages([
                          ...messages,
                          {
                            message,
                            time: "11:00",
                            isMe: true,
                            isRead: false,
                          },
                        ]);
                        setMessage("");
                      }}
                    >
                      <IconSend size="19px" color="#0B0A0A" />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </DrawerContent>
        <CustomModal
          isOpen={isCancelOrderOpen}
          onClose={() => {
            onCloseCancelOrder();
          }}
          onSubmit={() => {
            onCloseCancelOrder();
          }}
          noCloseFooter
          headerText={"Cancel Order"}
          btnText={"Confirm Cancellation"}
          width={"600px"}
        >
          <Flex p="32px 24px" flexDir="column" gap="32px">
            <Flex
              bg="rgba(34, 31, 31, 0.30)"
              p="16px"
              border="1px solid"
              borderColor="extsy.neutral800"
              borderRadius="16px"
              gap="10px"
              align="flex-start"
            >
              <Box>
                <IconInfoCircleFilled size="20px" color="#E35CAD" />
              </Box>
              <CustomText
                text={
                  <>
                    <span>
                      If you voluntarily cancel your order or exceed the time
                      limit 3 times in a day, your personal completion rate will
                      be affected However, if the order is cancelled due to the
                      seller’s reasons, the system will re-evaluate the
                      situation.{" "}
                    </span>
                    <span
                      style={{
                        color: "#E35CAD",
                      }}
                    >
                      View more
                    </span>
                  </>
                }
                color="extsy.baseWhite"
                lh="120%"
              />
            </Flex>
            <Flex
              py="16px"
              borderTop="1px solid"
              borderBottom="1px solid"
              borderColor="extsy.neutral700"
              flexDir="column"
              gap="16px"
            >
              <CustomText text="Due to buyer" color="extsy.baseWhite" />
              {BUYER_REASONS.map((reason, index) => (
                <Flex
                  key={index}
                  align="flex-start"
                  gap="10px"
                  cursor="pointer"
                  onClick={() => setSelectedReason(reason)}
                >
                  <Flex
                    borderColor="extsy.neutral300"
                    border="1px solid"
                    p="2px"
                    borderRadius="50%"
                    height="18px"
                    width="18px"
                    align="center"
                    justify="center"
                  >
                    <Circle
                      size="10px"
                      bg={
                        reason === selectedReason ? "extsy.g1" : "transparent"
                      }
                    />
                  </Flex>
                  <CustomText text={reason} cursor />
                </Flex>
              ))}
            </Flex>
            <Flex flexDir="column" gap="16px">
              <CustomText text="Due to seller" color="extsy.baseWhite" />
              {SELLER_REASONS.map((reason, index) => (
                <Flex
                  key={index}
                  align="flex-start"
                  gap="10px"
                  cursor="pointer"
                  onClick={() => setSelectedReason(reason)}
                >
                  <Flex
                    borderColor="extsy.neutral300"
                    border="1px solid"
                    p="2px"
                    borderRadius="50%"
                    height="18px"
                    width="18px"
                    align="center"
                    justify="center"
                  >
                    <Circle
                      size="10px"
                      bg={
                        reason === selectedReason ? "extsy.g1" : "transparent"
                      }
                    />
                  </Flex>
                  <CustomText text={reason} cursor />
                </Flex>
              ))}
            </Flex>
            <Flex gap="10px" align="center">
              <Image src={IconCheckMark} alt="checkmark" />
              <CustomText
                text="I have not paid the seller / have received seller’s refund"
                size="14px"
                color="extsy.white"
              />
            </Flex>
          </Flex>
        </CustomModal>
      </Drawer>
    </>
  );
}

export default OrderDetails;
