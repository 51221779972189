import { Box, Collapse, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import { IconMinus, IconPlus } from "@tabler/icons-react";

function FAQs() {
  const FAQS_DATA = [
    {
      title: "How do I increase my cashback limit?",
      description:
        "Easy as pie! You can increase your cashback limit up to 5 000 NOW without upgrading your VIP level. All you need to do is verify your ChangeNOW Pro account by passing the KYC procedure. Same rules apply for Emerald and Brilliant Levels. Identity verification increases your limit up to 100 000 NOW with Emerald level and up to 150 000 NOW with Brilliant level.",
    },
    {
      title: "What are the conditions for receiving cashback?",
      description:
        "Easy as pie! You can increase your cashback limit up to 5 000 NOW without upgrading your VIP level. All you need to do is verify your ChangeNOW Pro account by passing the KYC procedure. Same rules apply for Emerald and Brilliant Levels. Identity verification increases your limit up to 100 000 NOW with Emerald level and up to 150 000 NOW with Brilliant level.",
    },
    {
      title: "How long does it take to receive a cashback payout?",
      description:
        "Easy as pie! You can increase your cashback limit up to 5 000 NOW without upgrading your VIP level. All you need to do is verify your ChangeNOW Pro account by passing the KYC procedure. Same rules apply for Emerald and Brilliant Levels. Identity verification increases your limit up to 100 000 NOW with Emerald level and up to 150 000 NOW with Brilliant level.",
    },
    {
      title: "How can I use the tokens I receive?",
      description:
        "Easy as pie! You can increase your cashback limit up to 5 000 NOW without upgrading your VIP level. All you need to do is verify your ChangeNOW Pro account by passing the KYC procedure. Same rules apply for Emerald and Brilliant Levels. Identity verification increases your limit up to 100 000 NOW with Emerald level and up to 150 000 NOW with Brilliant level.",
    },
    {
      title: "How do I access more features of my Pro account?",
      description:
        "Easy as pie! You can increase your cashback limit up to 5 000 NOW without upgrading your VIP level. All you need to do is verify your ChangeNOW Pro account by passing the KYC procedure. Same rules apply for Emerald and Brilliant Levels. Identity verification increases your limit up to 100 000 NOW with Emerald level and up to 150 000 NOW with Brilliant level.",
    },
  ];

  const [isOpen, setIsOpen] = useState(-1);

  return (
    <Box
      p="24px"
      bg="extsy.neutral1000"
      borderRadius="24px"
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
    >
      <CustomText
        text="FAQs"
        size="24px"
        weight="700"
        family="Titillium Web"
        color="extsy.baseWhite"
      />
      <Flex flexDir="column" gap="16px" mt="24px">
        {FAQS_DATA.map((faq, idx) => (
          <Box
            key={idx}
            py="16px"
            pe="32px"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor={isOpen === idx ? "extsy.neutral700" : "transparent"}
          >
            <Flex align="center" justify="space-between">
              <Flex gap="10px" align="center">
                <CustomText text={`0${idx + 1}`} />
                <CustomText text={faq.title} color="extsy.baseWhite" />
              </Flex>
              {isOpen === idx ? (
                <IconMinus
                  color="#fafafa"
                  size="24px"
                  cursor="pointer"
                  onClick={() => setIsOpen(-1)}
                />
              ) : (
                <IconPlus
                  color="#fafafa"
                  size="24px"
                  cursor="pointer"
                  onClick={() => setIsOpen(idx)}
                />
              )}
            </Flex>
            <Collapse in={isOpen === idx} animateOpacity>
              <CustomText text={faq.description} mt="24px" />
            </Collapse>
          </Box>
        ))}
      </Flex>
    </Box>
  );
}

export default FAQs;
