import { Flex } from "@chakra-ui/react";
import { IconCheck } from "@tabler/icons-react";
import React from "react";
import CustomText from "../text/CustomText";

function CustomCheckbox({ isChecked, setChecked, label }: any) {
  return (
    <Flex gap="8px" align="center">
      <Flex
        align="center"
        justify="center"
        height="20px"
        width="20px"
        p="1px"
        borderRadius="4px"
        border="1px solid"
        borderColor="extsy.neutral300"
        cursor="pointer"
        onClick={() => setChecked(!isChecked)}
      >
        {isChecked && <IconCheck size="16px" color="#BAB8B8" />}
      </Flex>
      <CustomText text={label} size="14px" cursor="pointer" mt="3px" />
    </Flex>
  );
}

export default CustomCheckbox;
