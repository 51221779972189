import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import NoCardIco from "../../assets/images/no-card-ico.svg";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../button/PrimaryButton";
import CustomModal from "../modal/customModal";
import CancelAlertIco from "../../assets/images/cancel-alert-ico.svg";
import CardBg from "../../assets/images/card-bg.svg";
import ExtsyLogo from "../../assets/images/logo-icon.svg";
import MasterCardIco from "../../assets/images/master-card-ico.svg";
import { IconAlertTriangle, IconEye, IconEyeOff } from "@tabler/icons-react";
import TopUpIco from "../../assets/images/topup-ico.svg";

function OrderNowCard({ isVerified, setIsVerified }: any) {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isActivated, setIsActivited] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  return (
    <>
      {isVerified ? (
        <Flex
          align="center"
          gap="16px"
          flexDir="column"
          p="24px 0px 16px 0px"
          borderRadius="24px"
          bg="extsy.neutral1000"
          borderWidth="1px solid"
          borderColor="extsy.neutral1000"
        >
          <Box
            mx="24px"
            height="254px"
            width="406px"
            bgImage={`url(${CardBg})`}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPos="center"
            overflow="hidden"
            borderRadius="0px 0px 200px 200px"
          >
            <Box
              bg="rgba(0, 0, 0, 0.40)"
              borderRadius="22px"
              backdropFilter="blur(65px)"
              width="220px"
              height="370px"
              mx="auto"
              p="18px"
            >
              <Flex align="center" justify="space-between">
                <Image
                  src={ExtsyLogo}
                  alt="extsy"
                  draggable={false}
                  width="41px"
                  height="29px"
                />
                <Image
                  src={MasterCardIco}
                  alt="master-card"
                  draggable={false}
                  width="41px"
                  height="29px"
                />
              </Flex>
              {isActivated ? (
                <Flex flexDir="column" gap="26px" mt="56px">
                  <Flex flexDir="column" gap="6.3px">
                    <CustomText
                      text="Serra Alexandra"
                      size="10.7px"
                      color="extsy.baseWhite"
                    />
                    <CustomText
                      text={
                        isHidden ? "**** **** **** 3934" : "3674 3927 3782 3934"
                      }
                      size="15px"
                      Weight="700"
                      color="extsy.baseWhite"
                      ls="-0.604px"
                      family="Courier Prime"
                    />
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Flex flexDir="column" gap="6.3px">
                      <CustomText
                        text={t("expiryDate")}
                        color="extsy.baseWhite"
                        size="10.7px"
                      />
                      <CustomText
                        text={isHidden ? "**/**" : "03/28"}
                        color="extsy.baseWhite"
                        size="15px"
                        weight="700"
                        ls="-0.604px"
                        family="Courier Prime"
                        cursor="pointer"
                      />
                    </Flex>
                    <Flex flexDir="column" gap="6.3px">
                      <CustomText
                        text={t("cvc")}
                        color="extsy.baseWhite"
                        size="10.7px"
                      />
                      <CustomText
                        text={isHidden ? "***" : "947"}
                        color="extsy.baseWhite"
                        size="15px"
                        weight="700"
                        ls="-0.604px"
                        family="Courier Prime"
                        cursor="pointer"
                      />
                    </Flex>
                    {isHidden ? (
                      <IconEye
                        size="20px"
                        color="#FEF8C3"
                        cursor="pointer"
                        onClick={() => {
                          setIsHidden(false);
                        }}
                      />
                    ) : (
                      <IconEyeOff
                        size="20px"
                        color="#FEF8C3"
                        cursor="pointer"
                        onClick={() => {
                          setIsHidden(true);
                        }}
                      />
                    )}
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  flexDir="column"
                  justify="center"
                  align="center"
                  height="100%"
                >
                  <Flex
                    align="center"
                    justify="center"
                    gap="6px"
                    height="41px"
                    width="172px"
                    bg="#FFC700"
                    borderRadius="8px"
                    backdropFilter="blur(14px)"
                    mb="40px"
                  >
                    <IconAlertTriangle size="16px" color="#0B0A0A" />
                    <CustomText
                      text={t("yourCardNotActivated")}
                      size="12px"
                      color="extsy.bg"
                      family="Titillium Web"
                    />
                  </Flex>
                </Flex>
              )}
            </Box>
          </Box>
          <Divider bg="extsy.neutral900" opacity="0.5" height="1px" />
          {isActivated ? (
            <Flex align="center" justify="space-between" w="100%" px="52px">
              <Flex flexDir="column" gap="6px">
                <CustomText text={t("balanceAvailable")} size="12px" />
                <CustomText
                  text="$ 34,920.99"
                  size="22px"
                  ls="0.44px"
                  family="Titillium Web"
                  color="extsy.baseWhite"
                />
              </Flex>
              <Button
                display="flex"
                alignItems="center"
                gap="16px"
                height="44px"
                borderRadius="999px"
                ps="4px"
                bg="extsy.g1"
                _hover={{
                  bg: "extsy.g1",
                }}
              >
                <Image src={TopUpIco} alt="top-up" />
                <CustomText text={t("topUp")} size="14px" color="extsy.bg" />
              </Button>
            </Flex>
          ) : (
            <PrimaryButton
              title={t("activateNow")}
              width="159px"
              height="40px"
              onClick={() => {
                setIsActivited(true);
              }}
            />
          )}
        </Flex>
      ) : (
        <Flex
          align="center"
          gap="24px"
          flexDir="column"
          p="24px"
          borderRadius="24px"
          bg="extsy.neutral1000"
          borderWidth="1px solid"
          borderColor="extsy.neutral1000"
        >
          <Image src={NoCardIco} alt="no card" />
          <Flex flexDir="column" align="center" gap="12px">
            <CustomText
              text={t("noCardAvailable")}
              size="18px"
              weight="600"
              color="extsy.baseWhite"
            />
            <CustomText
              text={t("applyForVirtualCard")}
              color="extsy.neutral300"
            />
          </Flex>
          <PrimaryButton
            title={t("orderNowForUsd")}
            width="272px"
            onClick={onOpen}
          />
        </Flex>
      )}
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => {
          onClose();
          setIsVerified(true);
        }}
        headerText={t("createCreditCard")}
        btnText={t("completeKycNUpdagrade")}
        closeBtntext={t("close")}
        btnContinueWidth={"336px"}
        btnCloseWidth={"200px"}
        width={"600px"}
      >
        <Flex p="32px 24px" flexDir="column" gap="32px" alignItems="center">
          <Image src={CancelAlertIco} alt="cancel" />
          <CustomText
            text={t("verifyYourAccount")}
            color="extsy.baseWhite"
            size="24px"
            family="Tiltillium Web"
          />
          <CustomText
            text={t("verifyYourAccountToUnlockProFeature")}
            align="center"
            color="extsy.neutral300"
          />
        </Flex>
      </CustomModal>
    </>
  );
}

export default OrderNowCard;
