import { Box, Flex, Tab, TabList, Tabs } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../../components/text/CustomText";
import TableSearchInput from "../../../components/form/TableSearchInput";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import CustomCalendar from "../../../components/form/CustomCalendar";
import NoOrder from "./NoOrder";
import OrderTable from "./OrderTable";

function MyOrders() {
  const [tabIndex, setTabIndex] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    isCurrencyOpen: false,
    isCoinOpen: false,
    isBuySell: false,
    isCalendarOpen: false,
  });
  const [filters, setFilters] = useState({
    currency: "All Currency",
    coin: "All Coins",
    buySell: "Buy / Deposit",
    calendar: ["10-08-2024", "10-20-2024"],
  });

  const TABS = ["All", "In Progress", "Completed", "Cancelled"];
  const CURRENCIES = ["All Currency", "USD", "EURO", "POUND", "YUAN"];
  const COINS = ["All Coins", "BTC", "ETH", "BNB", "USDT"];
  const BUY_SELL = ["All", "Buy / Deposit", "Sell / Withdraw"];

  const toggleDropdown = (name: string) => {
    setIsDropdownOpen((prevState: any) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const closeDropdown = (name: string) => {
    setIsDropdownOpen((prevState: any) => ({ ...prevState, [name]: false }));
  };

  const formatedDate = () => {
    const dateObjStart = new Date(filters.calendar?.[0]);
    const dateObjEnd = new Date(filters.calendar?.[1]);
    return (
      dateObjStart.toLocaleDateString() +
      " - " +
      dateObjEnd.toLocaleDateString()
    );
  };

  return (
    <div>
      <Flex align="flex-start" justify="space-between">
        <Tabs onChange={(index) => setTabIndex(index)} defaultIndex={0}>
          <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
            {TABS?.map((tab, index) => (
              <Tab
                key={index}
                px="32px"
                pt="10px"
                pb="14px"
                color="extsy.secondary100"
              >
                <CustomText
                  text={tab}
                  color={
                    index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                  }
                  cursor="pointer"
                />
              </Tab>
            ))}
          </TabList>
        </Tabs>
        <Flex align="center" gap="10px">
          <TableSearchInput placeholder="Search" minW="157px" />

          <Box pos="relative">
            <Flex
              align="center"
              gap="16px"
              h="38px"
              ps="24px"
              pe="10px"
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              onClick={() => {
                toggleDropdown("isCoinOpen");
              }}
              cursor="pointer"
            >
              <CustomText
                text={filters.coin}
                size="12px"
                color="extsy.baseWhite"
                cursor
              />
              <IconChevronDown size="16px" color="#fafafa" />
            </Flex>
            <Flex
              p="16px"
              borderRadius="8px"
              bg="extsy.bg"
              flexDir="column"
              gap="16px"
              w="150px"
              display={isDropdownOpen?.isCoinOpen ? "flex" : "none"}
              pos="absolute"
              right="0"
              top="0"
              mt="45px"
              zIndex="1000"
            >
              {COINS.map((coin, index) => (
                <Flex
                  key={index}
                  align="center"
                  justify="space-between"
                  gap="8px"
                  cursor="pointer"
                  onClick={() => {
                    setFilters((prevState) => ({
                      ...prevState,
                      coin: coin,
                    }));
                    closeDropdown("isCoinOpen");
                  }}
                >
                  <CustomText
                    text={coin}
                    size="14px"
                    color={
                      coin === filters.coin
                        ? "extsy.baseWhite"
                        : "extsy.neutral300"
                    }
                    cursor
                  />
                  {coin === filters.coin && (
                    <IconCheck size="16px" color="#fafafa" />
                  )}
                </Flex>
              ))}
            </Flex>
          </Box>

          <Box pos="relative">
            <Flex
              align="center"
              gap="16px"
              h="38px"
              ps="24px"
              pe="10px"
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              onClick={() => {
                toggleDropdown("isCurrencyOpen");
              }}
              cursor="pointer"
            >
              <CustomText
                text={filters.currency}
                size="12px"
                color="extsy.baseWhite"
                cursor
              />
              <IconChevronDown size="16px" color="#fafafa" />
            </Flex>
            <Flex
              p="16px"
              borderRadius="8px"
              bg="extsy.bg"
              flexDir="column"
              gap="16px"
              w="150px"
              display={isDropdownOpen?.isCurrencyOpen ? "flex" : "none"}
              pos="absolute"
              right="0"
              top="0"
              mt="45px"
              zIndex="1000"
            >
              {CURRENCIES.map((currnecy, index) => (
                <Flex
                  key={index}
                  align="center"
                  justify="space-between"
                  gap="8px"
                  cursor="pointer"
                  onClick={() => {
                    setFilters((prevState) => ({
                      ...prevState,
                      currency: currnecy,
                    }));
                    closeDropdown("isCurrencyOpen");
                  }}
                >
                  <CustomText
                    text={currnecy}
                    size="14px"
                    color={
                      currnecy === filters.currency
                        ? "extsy.baseWhite"
                        : "extsy.neutral300"
                    }
                    cursor
                  />
                  {currnecy === filters.currency && (
                    <IconCheck size="16px" color="#fafafa" />
                  )}
                </Flex>
              ))}
            </Flex>
          </Box>
          <Box pos="relative">
            <Flex
              align="center"
              gap="16px"
              h="38px"
              ps="24px"
              pe="10px"
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              onClick={() => {
                toggleDropdown("isBuySell");
              }}
              cursor="pointer"
            >
              <CustomText
                text={filters.buySell}
                size="12px"
                color="extsy.baseWhite"
                cursor
              />
              <IconChevronDown size="16px" color="#fafafa" />
            </Flex>
            <Flex
              p="16px"
              borderRadius="8px"
              bg="extsy.bg"
              flexDir="column"
              gap="16px"
              w="150px"
              display={isDropdownOpen?.isBuySell ? "flex" : "none"}
              pos="absolute"
              right="0"
              top="0"
              mt="45px"
              zIndex="1000"
            >
              {BUY_SELL.map((buySell, index) => (
                <Flex
                  key={index}
                  align="center"
                  justify="space-between"
                  gap="8px"
                  cursor="pointer"
                  onClick={() => {
                    setFilters((prevState) => ({
                      ...prevState,
                      buySell: buySell,
                    }));
                    closeDropdown("isBuySell");
                  }}
                >
                  <CustomText
                    text={buySell}
                    size="14px"
                    color={
                      buySell === filters.buySell
                        ? "extsy.baseWhite"
                        : "extsy.neutral300"
                    }
                    cursor
                  />
                  {buySell === filters.buySell && (
                    <IconCheck size="16px" color="#fafafa" />
                  )}
                </Flex>
              ))}
            </Flex>
          </Box>
          <Box pos="relative">
            <Flex
              align="center"
              gap="16px"
              h="38px"
              ps="24px"
              pe="10px"
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              onClick={() => {
                toggleDropdown("isCalendarOpen");
              }}
              cursor="pointer"
            >
              <CustomText
                text={formatedDate()}
                size="12px"
                color="extsy.baseWhite"
                cursor
              />
              <IconChevronDown size="16px" color="#fafafa" />
            </Flex>
            <CustomCalendar
              isCalendarOpen={isDropdownOpen?.isCalendarOpen}
              value={filters.calendar}
              onChange={(val: any) => {
                console.log("-value", val);
                setFilters((prevState) => ({
                  ...prevState,
                  calendar: val,
                }));
                closeDropdown("isCalendarOpen");
              }}
              isRangePicker
            />
          </Box>
        </Flex>
      </Flex>
      <Box mt="40px">
        {
          // Render the content based on the selected tab
          tabIndex === 0 ? (
            <NoOrder />
          ) : tabIndex === 1 ? (
            <OrderTable status="In Process" />
          ) : tabIndex === 2 ? (
            <OrderTable status="Completed" />
          ) : tabIndex === 3 ? (
            <OrderTable status="Cancelled" />
          ) : null
        }
      </Box>
    </div>
  );
}

export default MyOrders;
