import { Box, Flex, Image, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import EmptyCard from "../../../assets/images/empty-card.svg";
import CustomText from "../../../components/text/CustomText";
import PrimaryButton from "../../../components/button/PrimaryButton";
import CustomModal from "../../../components/modal/customModal";
import VCardInput from "../../../components/form/vCardInput";
import BankOfAmerica from "../../../assets/images/bank-of-america.svg";
import {
  IconAlertTriangle,
  IconDotsVertical,
  IconPencilMinus,
  IconTrash,
} from "@tabler/icons-react";
import CustomSelect from "../../../components/form/CustomSelect";

function PaymentMethods() {
  const [isEmpty, setIsEmpty] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(-1);

  const {
    isOpen: isNickNameModalOpen,
    onOpen: openNickNameModal,
    onClose: closeNickNameModal,
  } = useDisclosure();
  const {
    isOpen: isAddBankOpen,
    onOpen: openAddBank,
    onClose: closeAddBank,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const toggleTab = (index: number) => {
    setIsMenuOpen(isMenuOpen === index ? -1 : index);
  };

  return (
    <>
      {isEmpty ? (
        <Flex
          minH="418px"
          flexDir="column"
          gap="24px"
          align="center"
          justify="center"
        >
          <Image src={EmptyCard} alt="empty" w="100px" h="62px" />
          <CustomText text="No payment method added" color="extsy.neutral400" />
          <PrimaryButton
            title="Add Payment Method"
            maxW="222px"
            onClick={openNickNameModal}
          />
        </Flex>
      ) : (
        <Flex gap="24px" align="center" wrap="wrap">
          {new Array(2)?.fill(1)?.map((_, index) => (
            <Box
              key={index}
              borderRadius="23px"
              border="1px solid"
              borderColor="extsy.neutral800"
              bg="rgba(255, 255, 255, 0.07)"
              backdropFilter="blur(17px)"
              maxW="469px"
              w="100%"
              pos="relative"
              overflow="hidden"
            >
              <Flex
                justify="space-between"
                align="center"
                p="16px 24px"
                borderBottom="1px solid"
                borderColor="extsy.neutral800"
                zIndex="100"
                pos="relative"
              >
                <Flex gap="10px" align="center">
                  <Image src={BankOfAmerica} alt="bank" />
                  <CustomText
                    text="Bank of America"
                    size="18px"
                    weight="600"
                    color="extsy.baseWhite"
                  />
                </Flex>
                <IconDotsVertical
                  size={20}
                  color="#fafafa"
                  cursor="pointer"
                  onClick={() => toggleTab(index)}
                />
                <Flex
                  flexDir="column"
                  gap="24px"
                  p="16px"
                  borderRadius="8px"
                  bg="extsy.neutral900"
                  border="1px solid"
                  borderColor="extsy.neutral800"
                  pos="absolute"
                  right="0"
                  top="0"
                  zIndex="1000"
                  mr="40px"
                  mt="35px"
                  display={isMenuOpen === index ? "flex" : "none"}
                >
                  <Flex
                    gap="12px"
                    align="flex-end"
                    cursor="pointer"
                    onClick={() => {
                      openAddBank();
                      toggleTab(index);
                    }}
                  >
                    <IconPencilMinus size={20} color="#fafafa" />
                    <CustomText
                      text="Edit"
                      color="extsy.baseWhite"
                      size="14px"
                      cursor
                    />
                  </Flex>
                  <Flex
                    gap="12px"
                    align="flex-end"
                    cursor="pointer"
                    onClick={() => {
                      onDeleteOpen();
                      toggleTab(index);
                    }}
                  >
                    <IconTrash size={20} color="#ED3D4E" />
                    <CustomText
                      text="Remove Account"
                      color="extsy.baseWhite"
                      size="14px"
                      cursor
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex mt="10px" zIndex="100" flexDir="column" gap="24px" p="24px">
                <Flex align="center" justify="space-between">
                  <CustomText text="Account Name" />
                  <CustomText text="John Doe" color="extsy.white" />
                </Flex>
                <Flex align="center" justify="space-between">
                  <CustomText text="Account Number" />
                  <CustomText text="0000 **** **** 8062" color="extsy.white" />
                </Flex>
                <Flex align="center" justify="space-between">
                  <CustomText text="Expiry Date" />
                  <CustomText text="Aug 02, 2030" color="extsy.white" />
                </Flex>
              </Flex>
              <Box
                w="210px"
                h="210px"
                bg="extsy.primary500"
                filter="blur(97px)"
                pos="absolute"
                bottom="0"
                right="0"
                mb="-216px"
                mr="129px"
              />
            </Box>
          ))}
        </Flex>
      )}
      <CustomModal
        isOpen={isNickNameModalOpen}
        onClose={closeNickNameModal}
        onSubmit={() => {
          closeNickNameModal();
          openAddBank();
        }}
        headerText={"Set Nickname"}
        btnText={"Confirm"}
        closeBtntext={"Cancel"}
        width={"600px"}
      >
        <Flex flexDir={"column"} gap={"32px"} py={"32px"} px={"24px"}>
          <CustomText
            text={
              "It is recommended not to use your real name. Nickname can only be modified once every 180 days."
            }
          />
          <Flex flexDir="column" gap="8px">
            <CustomText text={"Nickname"} size="14px" />
            <VCardInput
              placeholder=""
              type="text"
              value={""}
              radius="8px"
              bg={"extsy.neutral900"}
            />
            <CustomText
              text='Supports numbers, English letters, and special characters ("-" / "_").'
              size="14px"
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isAddBankOpen}
        onClose={closeAddBank}
        onSubmit={() => {
          closeAddBank();
          setIsEmpty(false);
        }}
        headerText={"Add Payment Method"}
        btnText={"Add"}
        closeBtntext={"Cancel"}
        width={"600px"}
        bgtopBlur="extsy.bgCardTopBlur"
        blurPos="right-top"
      >
        <Flex flexDir={"column"} gap={"32px"} py={"32px"} px={"24px"}>
          <Box>
            <CustomText text="Country Region" />
            <Flex mt="16px" gap="16px" align="center">
              <Flex flexDir="column" gap="12px" w="100%">
                <CustomText text="Country" size="14px" />
                <CustomSelect placeholder="Bank of America" />
              </Flex>
              <Flex flexDir="column" gap="12px" w="100%">
                <CustomText text="Zip Code" size="14px" />
                <VCardInput
                  placeholder=""
                  type="text"
                  value={""}
                  bg={"extsy.neutral900"}
                />
              </Flex>
            </Flex>
          </Box>
          <Box>
            <CustomText text="Card Information" />
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Bank Name" size="14px" />
              <VCardInput
                placeholder=""
                type="text"
                value={""}
                bg={"extsy.neutral900"}
              />
            </Flex>
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Select Bank" size="14px" />
              <CustomSelect placeholder="Select Bank" />
            </Flex>
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Account Number" size="14px" />
              <VCardInput
                placeholder="0000000000"
                type="text"
                value={""}
                bg={"extsy.neutral900"}
              />
            </Flex>
          </Box>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onSubmit={() => {
          onDeleteClose();
        }}
        headerText={"Delete Account"}
        btnText={"Yes, Delete"}
        closeBtntext={"Cancel"}
        width={"600px"}
      >
        <Flex
          flexDir={"column"}
          gap={"32px"}
          py={"32px"}
          px={"24px"}
          align="center"
        >
          <IconAlertTriangle size={40} color="#ED3D4E" />
          <CustomText text="Are you sure you want to delete?" />
        </Flex>
      </CustomModal>
    </>
  );
}

export default PaymentMethods;
