import React from "react";
import CustomText from "../text/CustomText";
import { Flex, Image } from "@chakra-ui/react";
import USDTIcon from "../../assets/images/usdt-ico.svg";
import DropDownIcon from "../../assets/images/SelectArrowdown.svg";
import { useTranslation } from "react-i18next";
const VCardSelect = () => {
  const { t } = useTranslation();

  return (
    <Flex
      justify={"space-between "}
      align={"center"}
      bg={"extsy.neutral900"}
      borderRadius={"16px"}
      padding={"9px 19px"}
      height={"56px"}
    >
      <Flex align={"center"} gap={"12px"}>
        <Image src={USDTIcon} alt="icon" />
        <CustomText
          text={t("usdt")}
          size="16px"
          weight="600"
          color="extsy.baseWhite"
          family="Metropolis"
        />
      </Flex>
      <Image src={DropDownIcon} alt="icon" />
    </Flex>
  );
};

export default VCardSelect;
