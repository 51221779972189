import {
  Avatar,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../../components/text/CustomText";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { MY_LISTINGS } from "../../../utils/constants";
import ArrowBack from "../../../assets/images/black-left.svg";
import ArrowForward from "../../../assets/images/black-right.svg";
import GradientText from "../../../components/text/GradientText";
import CustomButton from "../../../components/button/CustomButton";
import CustomModal from "../../../components/modal/customModal";
import EthIco from "../../../assets/images/eth-ico.svg";

function MyListing() {
  const { t } = useTranslation();

  const {
    isOpen: isDelistOpen,
    onClose: onDelistClose,
    onOpen: onDelistOpen,
  } = useDisclosure();

  return (
    <Flex flexDir="column" gap="24px">
      <TableContainer minH="250px">
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Coin Type"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text="Total Amount"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text="Available"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="BTC Value"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={t("actions")}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {MY_LISTINGS.map((order: any, index) => (
              <Tr key={index}>
                <Td
                  height="64px"
                  borderColor="extsy.neutral900"
                  borderRight="1px solid"
                  py="0"
                >
                  <Flex gap="12px" align="center">
                    <Avatar src={order?.coin?.icon} boxSize="24px" />
                    <Flex gap="8px" flexDir="column">
                      <CustomText
                        text={order.coin?.symbol}
                        size="14px"
                        color="extsy.baseWhite"
                      />
                      <CustomText
                        text={`${order?.coin?.name}`}
                        size="12px"
                        color="extsy.text5"
                      />
                    </Flex>
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText
                    text={order?.amount}
                    size="14px"
                    weight="500"
                    align="end"
                  />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText
                    text={order?.available}
                    size="14px"
                    weight="500"
                    align="end"
                  />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex align="center" justify="space-between">
                    <CustomText
                      text={order?.btc?.value}
                      size="14px"
                      weight="500"
                    />
                    <CustomText
                      text={`≈ $${order?.btc?.usd}`}
                      size="14px"
                      weight="500"
                      color="extsy.neutral500"
                    />
                  </Flex>
                </Td>
                <Td
                  borderColor="extsy.neutral900"
                  borderRight="1px solid"
                  maxW="124px"
                >
                  <CustomButton
                    title="Delist"
                    bg="extsy.neutral800"
                    color="extsy.baseWhite"
                    height="28px"
                    width="84px"
                    size="12px"
                    onClick={onDelistOpen}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        <CustomText
          text={`1-10 ${t("of")} 50`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />
        <Flex gap="8px">
          <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="2" size="14px" weight="500" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="3" size="14px" weight="500" />
          </Flex>
          <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
        </Flex>
      </Flex>
      <CustomModal
        isOpen={isDelistOpen}
        onClose={onDelistClose}
        onSubmit={() => {
          onDelistClose();
        }}
        headerText={"Delisting"}
        btnText={"Confirm, Delist"}
        closeBtntext={"Cancel"}
        width={"600px"}
      >
        <Flex
          flexDir={"column"}
          gap={"32px"}
          py={"32px"}
          px={"24px"}
          align="center"
        >
          <CustomText text="Are you sure you want to delist?" />
          <Flex align="center" gap="16px">
            <Image src={EthIco} alt="eth-ico" boxSize="32px" />
            <Flex align="center" gap="8px">
              <CustomText text="ETH" color="extsy.baseWhite" />
              <CustomText text="Ethereum" />
            </Flex>
          </Flex>
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default MyListing;
