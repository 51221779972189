import { Box, Flex, Image, Input, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import SwapAmountInput from "../form/SwapAmountInput";
import AmountRaising from "../../assets/images/amount-rising.svg";
import EthIco from "../../assets/images/eth-ico.svg";
import BtcIco from "../../assets/images/btc-ico.svg";
import { IconChevronDown } from "@tabler/icons-react";
import PrimaryButton from "../button/PrimaryButton";
import UnlockIco from "../../assets/images/unlock-ico.svg";
import InfoIco from "../../assets/images/info-ico.svg";
import SwapIcon from "../../assets/images/swap-icon.svg";
import LockIcon from "../../assets/images/lock-green.svg";
import CryptoCurrencyDropdown from "../form/CryptoCurrencyDropdown";
import CopyClipboard from "../../assets/images/copy-clipboard.svg";
import AgreeCheckbox from "../../assets/images/agree-checkbox.svg";

function SwapCard({ swap }: any) {
  const { t } = useTranslation();

  const [isLessThan1242] = useMediaQuery("(max-width: 1242px)");

  const [isFromSwap, setIsFromSwap] = useState(swap || false);
  const [isPayingCrypto, setIsPayingCrypto] = useState(true);
  const [isFixedPrice, setIsFixedPrice] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState<any>({
    currency1: {
      symbol: "BTC",
      name: "Bitcoin",
      icon: BtcIco,
      price: "$56,623.54",
    },
    currency2: {
      symbol: "ETH",
      name: "Ethereum",
      icon: EthIco,
      price: "$3,456.54",
    },
  });
  const [isCurrency1Open, setIsCurrency1Open] = useState(false);
  const [isCurrency2Open, setIsCurrency2Open] = useState(false);

  useEffect(() => {
    if (isLessThan1242) {
      setIsFromSwap(false);
    } else {
      setIsFromSwap(swap);
    }
  }, [isLessThan1242, swap]);

  return (
    <Box position="relative">
      <Flex flexDir={isFromSwap ? "row" : "column"} gap="10px">
        {/* Amount swapping */}
        <Box
          p="30px 27px 35px 27px"
          borderRadius="20.5px"
          border="0.857px solid"
          borderColor="extsy.white"
          backdropBlur="blur(5.39px)"
          pos="relative"
          width={isFromSwap ? "100%" : "auto"}
        >
          <Flex align="center" justify="space-between" gap="16px">
            <Flex flexDir="column" gap="9px" maxW="164px">
              <SwapAmountInput />
              <Flex align="center" gap="7px">
                <Image src={AmountRaising} alt="up" />
                <CustomText
                  text="+ 3,390 (5.94%)"
                  size="15.4px"
                  family="Titillium Web"
                  color="extsy.white"
                />
              </Flex>
            </Flex>
            <Flex align="center" gap="10px">
              {isPayingCrypto ? (
                <PayingCrypto
                  onClick={() => {
                    setIsCurrency1Open(!isCurrency1Open);
                  }}
                  activeCurrency={activeCurrency?.currency1}
                />
              ) : (
                <ReceivingCrypto
                  onClick={() => {
                    setIsCurrency2Open(!isCurrency2Open);
                  }}
                  activeCurrency={activeCurrency?.currency2}
                />
              )}
            </Flex>
          </Flex>
          {isCurrency1Open && (
            <CryptoCurrencyDropdown
              activeCurrency={activeCurrency.currency1}
              onClick={(currency: any) => {
                setActiveCurrency({
                  ...activeCurrency,
                  currency1: currency,
                });
                setIsCurrency1Open(false);
                setIsCurrency2Open(false);
              }}
            />
          )}
        </Box>
        <Image
          src={SwapIcon}
          alt="swap"
          cursor="pointer"
          mx="auto"
          ml={isFromSwap ? "-25px" : ""}
          mt={!isFromSwap ? "-35px" : ""}
          zIndex="1000"
          onClick={() => {
            setIsCurrency1Open(false);
            setIsCurrency2Open(false);
            setIsPayingCrypto(!isPayingCrypto);
          }}
        />
        {/* Amount receiving */}
        <Box
          mt={isFromSwap ? "" : "-35px"}
          ml={isFromSwap ? "-25px" : ""}
          p="30px 27px 35px 27px"
          borderRadius="20.5px"
          border="0.857px solid"
          borderColor="extsy.white"
          backdropBlur="blur(5.39px)"
          pos="relative"
          width={isFromSwap ? "100%" : "auto"}
        >
          <Flex align="center" justify="space-between" gap="16px">
            <Flex flexDir="column" gap="9px" maxW="164px">
              <SwapAmountInput />
              <Flex align="center" gap="7px">
                <Image src={AmountRaising} alt="up" />
                <CustomText
                  text="+ 3,390 (5.94%)"
                  size="15.4px"
                  family="Titillium Web"
                  color="extsy.white"
                />
              </Flex>
            </Flex>
            <Flex align="center" gap="10px">
              <Image
                src={isFixedPrice ? LockIcon : UnlockIco}
                alt="unlock"
                pr="8px"
                height="30px"
                width="30px"
                borderRight="1px solid"
                borderColor="extsy.text"
                cursor="pointer"
                onClick={() => setIsFixedPrice(!isFixedPrice)}
              />
              {!isPayingCrypto ? (
                <PayingCrypto
                  onClick={() => {
                    setIsCurrency1Open(!isCurrency1Open);
                  }}
                  activeCurrency={activeCurrency?.currency1}
                />
              ) : (
                <ReceivingCrypto
                  onClick={() => {
                    setIsCurrency2Open(!isCurrency2Open);
                  }}
                  activeCurrency={activeCurrency?.currency2}
                />
              )}{" "}
            </Flex>
          </Flex>
          {isCurrency2Open && (
            <CryptoCurrencyDropdown
              activeCurrency={activeCurrency.currency2}
              onClick={(currency: any) => {
                setActiveCurrency({
                  ...activeCurrency,
                  currency2: currency,
                });
                setIsCurrency1Open(false);
                setIsCurrency2Open(false);
              }}
            />
          )}
        </Box>
      </Flex>
      {swap && (
        <Box pos="relative" mt={isLessThan1242 ? "12px" : "24px"}>
          <Input
            p="24px 48px 24px 24px"
            height="70px"
            type="text"
            borderRadius="12px"
            border="1px solid"
            borderColor="extsy.white"
            placeholder={t("receipientWalletAddress")}
            fontSize="18px"
            fontWeight="400"
            fontFamily="Titillium Web"
            color="extsy.white"
            _hover={{ borderColor: "extsy.white" }}
            _focus={{ borderColor: "extsy.white" }}
            _focusVisible={{ borderColor: "extsy.white" }}
          />
          <Image
            src={CopyClipboard}
            alt="copy"
            pos="absolute"
            right="0"
            top="0"
            mr="24px"
            mt="24px"
          />
        </Box>
      )}
      <Flex
        flexDir={isFromSwap ? { base: "column", md: "row" } : "column"}
        align={isFromSwap ? { base: "left", md: "center" } : "left"}
        mb="20px"
        mt={isFromSwap ? "24px" : "8px"}
        gap="10px"
        px={isFromSwap ? { base: "43px", md: "0px" } : "43px"}
      >
        <Flex align="center" gap="10px" justify="space-between">
          <Flex align="center" gap="6px">
            <CustomText
              text={t("noHiddenFee")}
              size="10px"
              family="Titillium Web"
              color="extsy.white"
              underline
              cursor="pointer"
            />
            <Image src={InfoIco} alt="info" />
          </Flex>
          <Flex align="center" gap="6px">
            <CustomText
              text={t("comparePrices")}
              size="10px"
              family="Titillium Web"
              color="extsy.white"
              underline
              cursor="pointer"
            />
            <Image src={InfoIco} alt="info" />
          </Flex>
        </Flex>
        <Flex align="center" gap="6px">
          <Flex align="center" gap="6px">
            <CustomText
              text={t("estimatedRate")}
              size="10px"
              family="Titillium Web"
              color="extsy.white"
              underline
              cursor="pointer"
            />
            <Image src={InfoIco} alt="info" />
          </Flex>
          <CustomText
            text="1 BTC ~ 22.22413 ETH"
            size="10px"
            family="Titillium Web"
            color="extsy.white"
          />
        </Flex>
      </Flex>
      {swap && (
        <Flex my={isLessThan1242 ? "12px" : "17px"} gap="8px" align="center">
          <Image src={AgreeCheckbox} alt="agree" />
          <CustomText
            text={t("readAgreeTerms")}
            family="Titillium Web"
            size="12px"
            lh="16px"
            color="extsy.white"
          />
        </Flex>
      )}
      <PrimaryButton title={t("swap")} width="100%" height="47px" />
    </Box>
  );
}

const PayingCrypto = ({ onClick, activeCurrency }: any) => {
  return (
    <Flex gap="10px" align="center" onClick={onClick}>
      <Flex flexDir="column" gap="2px" align="flex-end" cursor="pointer">
        <Flex align="center" gap="8px">
          <IconChevronDown color="#fff" size="16px" />
          <CustomText
            text={activeCurrency.symbol}
            size="20px"
            weight="700"
            family="Titillium Web"
            color="extsy.white"
            cursor="pointer"
          />
        </Flex>
        <CustomText
          text={activeCurrency.name}
          size="15px"
          color="extsy.white"
          family="Titillium Web"
          cursor="pointer"
        />
      </Flex>
      <Image
        src={activeCurrency?.icon}
        alt="btc"
        cursor="pointer"
        height="48px"
        width="48px"
      />
    </Flex>
  );
};

const ReceivingCrypto = ({ onClick, activeCurrency }: any) => {
  return (
    <Flex align="center" gap="10px" cursor="pointer" onClick={onClick}>
      <Flex flexDir="column" gap="2px" align="flex-end">
        <Flex align="center" gap="8px">
          <IconChevronDown color="#fff" size="16px" />
          <CustomText
            text={activeCurrency.symbol}
            size="20px"
            weight="700"
            family="Titillium Web"
            color="extsy.white"
            cursor="pointer"
          />
        </Flex>
        <CustomText
          text={activeCurrency.name}
          size="15px"
          color="extsy.white"
          family="Titillium Web"
          cursor="pointer"
        />
      </Flex>
      <Image
        src={activeCurrency?.icon}
        alt="eth"
        cursor="pointer"
        height="48px"
        width="48px"
      />
    </Flex>
  );
};

export default SwapCard;
