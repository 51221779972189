import { Flex, Image, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ExtsyBg from "../assets/images/auth-bg.svg";
import LogoIcon from "../assets/images/logo-icon.svg";
import AuthSlider from "../components/auth/AuthSlider";
function AuthLayout() {
  const navigate = useNavigate();

  const [isLessThan768] = useMediaQuery("(max-height: 768px)");
  const [isLessThan1920] = useMediaQuery("(max-width: 1919px)");

  useEffect(() => {
    if (isLessThan1920) {
      navigate("/coming-soon");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLessThan1920]);

  return (
    <Flex
      bg="extsy.bg"
      minH="100vh"
      align="center"
      justify="space-between"
      p="20px"
      gap="40px"
    >
      <Outlet />
      <Flex
        display={{ base: "none", lg: "flex" }}
        maxH="590px"
        minH={isLessThan768 ? "460px" : "590px"}
        flexDir="column"
        justify="space-between"
        bgImage={ExtsyBg}
        bgSize="contain"
        bgRepeat="no-repeat"
        bgPos="center"
        maxW={{ base: "630px", "2xl": "760px" }}
        w="100%"
      >
        <Image
          draggable={false}
          src={LogoIcon}
          alt="logo"
          width="55px"
          height="40px"
        />
        <AuthSlider />
      </Flex>
    </Flex>
  );
}

export default AuthLayout;
