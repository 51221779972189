import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import Countdown from "react-countdown";
import ColonIcon from "../../assets/images/colon-icon.svg";
import FlipBg from "../../assets/images/timer-flip-bg.svg";

function NextDraw() {
  return (
    <Flex
      p="20px 32px 20px 32px"
      borderRadius="16px"
      bg="extsy.bg"
      flexDir="column"
      gap="24px"
      maxW="440px"
      width="100%"
      zIndex="100"
      overflow="hidden"
      pos="relative"
    >
      <CustomText
        text="Next Draw Coming In"
        size="20px"
        lh="30px"
        align="center"
        family="Titillium Web"
        weight="600"
        color="extsy.neutral50"
      />
      <Countdown
        date={Date.now() + 5 * 24 * 60 * 60 * 1000}
        key={Date.now() + 5 * 24 * 60 * 60 * 1000}
        renderer={flipTimer}
      />
      <Box
        height="193px"
        width="193px"
        bg="#15B097"
        filter="blur(82.5px)"
        borderRadius="193px"
        pos="absolute"
        top="0"
        right="0"
        mt="-132px"
        mr="-118px"
      />
    </Flex>
  );
}

const flipTimer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return (
      <CustomText text="Draw is live" size="20px" color="extsy.neutral50" />
    );
  } else {
    return (
      <Flex justify="center" align="center" gap="8px">
        <Flex flexDir="row" align="center" gap="8px">
          <Flex gap="8px" flexDir="column" align="center">
            <Flex
              bgImage={FlipBg}
              bgSize="cover"
              align="center"
              justify="center"
              height="72px"
              width="83px"
            >
              <CustomText
                text={days < 10 ? `0${days}` : days}
                size="70px"
                color="extsy.neutral50"
                family="Share Tech"
              />
            </Flex>
            <CustomText
              text="DAYS"
              size="12px"
              weight="600"
              color="extsy.neutral50"
            />
          </Flex>
          <Image src={ColonIcon} alt="colon-icon" mt="-15px" />
        </Flex>
        <Flex flexDir="row" align="center" gap="8px">
          <Flex gap="8px" flexDir="column" align="center">
            <Flex
              bgImage={FlipBg}
              bgSize="cover"
              align="center"
              justify="center"
              height="72px"
              width="83px"
            >
              <CustomText
                text={hours < 10 ? `0${hours}` : hours}
                size="70px"
                color="extsy.neutral50"
                family="Share Tech"
              />
            </Flex>
            <CustomText
              text="HOURS"
              size="12px"
              weight="600"
              color="extsy.neutral50"
            />
          </Flex>
          <Image src={ColonIcon} alt="colon-icon" mt="-15px" />
        </Flex>
        <Flex flexDir="row" align="center" gap="8px">
          <Flex gap="8px" flexDir="column" align="center">
            <Flex
              bgImage={FlipBg}
              bgSize="cover"
              align="center"
              justify="center"
              height="72px"
              width="83px"
            >
              <CustomText
                text={minutes < 10 ? `0${minutes}` : minutes}
                size="70px"
                color="extsy.neutral50"
                family="Share Tech"
              />
            </Flex>
            <CustomText
              text="MINUTES"
              size="12px"
              weight="600"
              color="extsy.neutral50"
            />
          </Flex>
          <Image src={ColonIcon} alt="colon-icon" mt="-15px" />
        </Flex>
        <Flex gap="8px" flexDir="column" align="center">
          <Flex
            bgImage={FlipBg}
            bgSize="cover"
            align="center"
            justify="center"
            height="72px"
            width="83px"
          >
            <CustomText
              text={seconds < 10 ? `0${seconds}` : seconds}
              size="70px"
              color="extsy.neutral50"
              family="Share Tech"
            />
          </Flex>
          <CustomText
            text="SECONDS"
            size="12px"
            weight="600"
            color="extsy.neutral50"
          />
        </Flex>
      </Flex>
    );
  }
};

export default NextDraw;
