import { Avatar, Button, Flex, Image, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import WalletIcon from "../../assets/images/connect-wallet-ico.svg";
import PrimeIco from "../../assets/images/prime-ico.svg";
import NotificationIco from "../../assets/images/notification-ico.svg";
import LanguageIco from "../../assets/images/language-ico.svg";
import { useTranslation } from "react-i18next";
import Notifications from "./Notifications";
import { IconShield, IconX } from "@tabler/icons-react";
import CustomModal from "../modal/customModal";
import Languages from "./Languages";

function Navbar() {
  const { t } = useTranslation();
  const {
    isOpen: isDisconnectOpen,
    onOpen: onDisconnectOpen,
    onClose: onDisconnectClose,
  } = useDisclosure();

  const [isNotificaitonOpen, setIsNotificationOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(
    localStorage.getItem("isConnected") || false
  );

  return (
    <Flex gap="24px" w="100%" flexDir="column">
      <Flex justify="space-between" align="center" gap="24px" w="100%">
        <CustomText
          text={t("welcomeText")}
          family="Titillium Web"
          size="28px"
          weight="700"
          color="extsy.neutral50"
          lh="36px"
        />
        <Flex gap="16px" align="center">
          <Button
            bg="extsy.g1"
            borderRadius="999px"
            height="48px"
            display="flex"
            alignItems="center"
            ps="4px"
            pe="16px"
            gap="10px"
            _hover={{ bg: "extsy.g1" }}
            onClick={() => {
              if (isConnected) {
                onDisconnectOpen();
              } else {
                localStorage.setItem("isConnected", "true");
                setIsConnected(true);
              }
            }}
          >
            <Image src={WalletIcon} alt="wallet" />

            <CustomText
              text={isConnected ? "0x0000...00000000" : t("connectWallet")}
              size="14px"
              weight="600"
              family="Titillium Web"
              color="extsy.bg"
              cursor="pointer"
              ls="0.28px"
            />
            {isConnected && <IconX size={18} color="#000" />}
          </Button>
          <Flex
            cursor="pointer"
            bg="rgba(250, 250, 250, 0.10)"
            align="center"
            gap="8px"
            px="16px"
            h="32px"
            borderRadius="999px"
          >
            <Image src={PrimeIco} alt="prime" />
            <CustomText
              text={t("prime")}
              color="extsy.baseWhite"
              size="14px"
              cursor="pointer"
            />
          </Flex>
          <Flex align="center" gap="8px">
            <Avatar
              src=""
              name="SA"
              boxSize="48px"
              bg="extsy.g1"
              color="extsy.bg"
            />
            <Flex flexDir="column" gap="6px">
              <CustomText
                text="Serra Alexandra"
                color="extsy.baseWhite"
                weight="600"
              />
              <Flex align="center" gap="8px">
                <IconShield size="16px" color="#fff" />
                <CustomText text="Verify Account" size="10px" weight="500" />
              </Flex>
            </Flex>
          </Flex>
          <Flex align="center" gap="8px">
            <Flex
              flexDir="column"
              justify="center"
              align="center"
              height="48px"
              width="48px"
              bg="extsy.neutral1000"
              borderRadius="99px"
              border="1px solid"
              borderColor="extsy.neutral900"
              cursor="pointer"
              pos="relative"
              onClick={() => {
                setIsNotificationOpen(false);
                setIsLangOpen(!isLangOpen);
              }}
            >
              <Image src={LanguageIco} alt="lang" boxSize="24px" />
              {isLangOpen && <Languages />}
            </Flex>
            <Flex
              flexDir="column"
              justify="center"
              align="center"
              height="48px"
              width="48px"
              bg="extsy.neutral1000"
              borderRadius="99px"
              border="1px solid"
              borderColor="extsy.neutral900"
              cursor="pointer"
              pos="relative"
              onClick={() => {
                setIsNotificationOpen(!isNotificaitonOpen);
                setIsLangOpen(false);
              }}
            >
              <Image src={NotificationIco} alt="notification" boxSize="24px" />
              {isNotificaitonOpen && <Notifications />}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <CustomModal
        isOpen={isDisconnectOpen}
        onClose={() => {
          onDisconnectClose();
        }}
        onSubmit={() => {
          onDisconnectClose();
          setIsConnected(false);
          localStorage.removeItem("isConnected");
        }}
        headerText={t("disconnectWallet")}
        btnText={t("yesDisconnect")}
        closeBtntext={t("cancel")}
        width={"600px"}
      >
        <Flex p="32px 24px" flexDir="column" gap="32px" alignItems="center">
          <CustomText
            text={t("areYouSureDisconnectWallet")}
            align="center"
            color="extsy.neutral300"
          />
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default Navbar;
