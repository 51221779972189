import { Box, Flex, Image, Input } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../text/CustomText";
import { IconSearch } from "@tabler/icons-react";
import { CRYPTO_CURRENCIES } from "../../utils/constants";

const CryptoCurrencyDropdown = ({ activeCurrency, onClick }: any) => {
  const { t } = useTranslation();
  return (
    <Flex
      flexDir="column"
      p="15px 12px 16px 16px"
      bg="extsy.neutral900"
      borderRadius="16px"
      border="1px solid"
      borderColor="extsy.neutral800"
      flexDirection="column"
      position={"absolute"}
      width="90%"
      right="0"
      mr="25px"
      zIndex="10000"
      maxH="372px"
      overflowY="auto"
      gap="12px"
    >
      <Flex align="center" justify="space-between">
        <CustomText
          text={t("coin")}
          color="extsy.white"
          family="Titillium Web"
        />
        <CustomText
          text={t("price")}
          color="extsy.white"
          family="Titillium Web"
        />
      </Flex>
      <Box position="relative">
        <IconSearch
          size="16px"
          color="#fff"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            marginTop: "8px",
            marginLeft: "8px",
          }}
        />
        <Input
          border="1px solid"
          borderColor="extsy.text"
          borderRadius="999px"
          pl="30px"
          pe="16px"
          py="8px"
          h="33px"
          fontSize="14px"
          fontFamily="Titillium Web"
          placeholder="Search Coin"
          color="extsy.white"
          _hover={{
            borderColor: "extsy.primary100",
          }}
          _focus={{
            borderColor: "extsy.primary100",
          }}
          _focusVisible={{
            borderColor: "extsy.primary100",
          }}
        />
      </Box>
      <Flex flexDir="column" gap="4px">
        {CRYPTO_CURRENCIES.map((currency, index) => (
          <Flex
            key={index}
            justify="space-between"
            align="center"
            p="8px"
            justifyContent="space-between"
            cursor="pointer"
            onClick={() => {
              onClick(currency);
            }}
            borderRadius="4px"
            bg={
              activeCurrency?.symbol === currency.symbol
                ? "extsy.baseWhite"
                : ""
            }
          >
            <Flex alignItems="center" gap="10px">
              <Image
                src={currency.icon}
                alt={currency.symbol}
                height="24px"
                width="24px"
              />
              <CustomText
                text={currency.symbol}
                color={
                  activeCurrency?.symbol === currency?.symbol
                    ? "extsy.dark300"
                    : "extsy.neutral300"
                }
                family="Titillium Web"
                cursor="pointer"
              />
            </Flex>
            <CustomText
              text={currency.price}
              color={
                activeCurrency?.symbol === currency?.symbol
                  ? "extsy.dark300"
                  : "extsy.neutral300"
              }
              family="Titillium Web"
              cursor="pointer"
            />
          </Flex>
        ))}{" "}
      </Flex>
    </Flex>
  );
};

export default CryptoCurrencyDropdown;
