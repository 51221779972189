import {
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../../components/text/CustomText";
import {
  IconArrowDownLeft,
  IconArrowUpRight,
  IconChevronDown,
  IconChevronUp,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ArrowBack from "../../../assets/images/black-left.svg";
import ArrowForward from "../../../assets/images/black-right.svg";
import GradientText from "../../../components/text/GradientText";
import { P2P_BALANCES } from "../../../utils/constants";

function P2PBalanceTable() {
  const { t } = useTranslation();
  return (
    <Flex flexDir="column" gap="24px">
      <TableContainer>
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900" maxW="583px" minW="260px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Coin"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="183px" minW="120px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Chain"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="583px" minW="260px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Total Amount"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="583px" minW="260px">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text={"Available Amount"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="583px" minW="260px">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text={"Freeze Amount"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={t("actions")}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {P2P_BALANCES.map((balance, index) => (
              <Tr key={index}>
                <Td
                  height="54px"
                  borderColor="extsy.neutral900"
                  borderRight="1px solid"
                  py="0"
                >
                  <Flex gap="16px" align="center">
                    <Image
                      src={balance.icon}
                      alt={balance?.symbol}
                      boxSize="32px"
                    />
                    <CustomText
                      text={balance.symbol}
                      size="14px"
                      color="extsy.baseWhite"
                    />
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText text={balance.chain} size="14px" />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex align="center" justify="space-between" gap="24px">
                    <CustomText
                      text={balance.amount + " " + balance?.symbol}
                      size="14px"
                    />
                    <CustomText
                      text={balance.amountUsd + " USD"}
                      size="14px"
                      color="extsy.neutral600"
                    />
                  </Flex>
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText
                    text={balance.availableBalance + " " + balance?.symbol}
                    size="14px"
                    align="end"
                  />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <CustomText
                    text={balance.freezeAmount}
                    size="14px"
                    align="end"
                  />
                </Td>
                <Td borderColor="extsy.neutral900" borderRight="1px solid">
                  <Flex gap="16px" align="center">
                    <Flex cursor="pointer" gap="4px" align="center">
                      <IconArrowDownLeft size="18px" color="#908B8B" />
                      <CustomText
                        text={"Deposit"}
                        size="10px"
                        cursor="pointer"
                        color="extsy.baseWhite"
                      />
                    </Flex>
                    <Box bg="extsy.neutral900" height="9px" width="1px" />
                    <Flex cursor="pointer" gap="4px" align="center">
                      <IconArrowUpRight size="18px" color="#908B8B" />
                      <CustomText
                        text={"Withdraw"}
                        size="10px"
                        cursor="pointer"
                        color="extsy.baseWhite"
                      />
                    </Flex>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        <CustomText
          text={`1-10 ${t("of")} 50`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />
        <Flex gap="8px">
          <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <GradientText text="1" size="14px" weight="500" bg="extsy.g1" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="2" size="14px" weight="500" />
          </Flex>
          <Flex
            flexDir="column"
            align="center"
            justify="center"
            bg="extsy.neutral900"
            borderRadius="6px"
            height="30px"
            width="30px"
          >
            <CustomText text="3" size="14px" weight="500" />
          </Flex>
          <Image src={ArrowForward} alt="arrow-forward" cursor="pointer" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default P2PBalanceTable;
