import { Flex } from "@chakra-ui/react";
import React from "react";
import TradingEstimateBalanceCard from "../../../components/cards/TradingEstimateBalanceCard";
import CustomText from "../../../components/text/CustomText";
import TableSearchInput from "../../../components/form/TableSearchInput";
import P2PBalanceTable from "./P2PBalanceTable";

function P2PBalance() {
  return (
    <Flex flexDir="column" gap="40px">
      <TradingEstimateBalanceCard />
      <Flex align="center" justify="space-between">
        <CustomText
          text="P2p Balance"
          size="24px"
          lh="34px"
          weight="700"
          family="Titillium Web"
          color="extsy.baseWhite"
        />
        <TableSearchInput placeholder="Search " />
      </Flex>
      <P2PBalanceTable />
    </Flex>
  );
}

export default P2PBalance;
