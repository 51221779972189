import React from 'react'
import VirtualCards from '../../components/cards/virtualCards'
import DetailCard from '../../components/cards/DetailCard'
import RecentTransactions from '../../components/dashboard/RecentTransactions'
import { Grid, GridItem} from '@chakra-ui/react'

function VirtualCard() {
  return (
   
    <Grid
    gap="24px"
    templateRows="repeat(1, 1fr)"
    templateColumns="repeat(12, 1fr)"
  >
    <GridItem colSpan={8}>
    <VirtualCards/>
    </GridItem>
    <GridItem colSpan={4}>
    <DetailCard/>
    </GridItem>
    <GridItem colSpan={8}>
      <RecentTransactions minH={"201px"}  mt={"-26.2rem"}/>
    </GridItem>
   
  </Grid>
  )
}

export default VirtualCard