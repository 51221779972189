import { Box, Flex, Image } from "@chakra-ui/react";
import CustomText from "../text/CustomText";
import SliderImg1 from "../../assets/images/sliderImg1.svg";
import SliderImg2 from "../../assets/images/sliderImg2.svg";
import { useTranslation } from "react-i18next";

function DashboardSlider() {
  const { t } = useTranslation();

  return (
    <Flex
      minW={"548px"}
      justify={"center"}
      flexDir={"column"}
      align={"center"}
      width="100%"
    >
      <Flex align={"center"} justify={"center"}>
        <Flex flexDir={"column"}>
          <Image
            src={SliderImg1}
            alt="SliderImg1"
            width={"36px"}
            height={"36px"}
          />
        </Flex>
        <Box
          bg={"extsy.neutral300"}
          w={`220px`}
          h="2px"
          borderRadius="full"
        ></Box>
        <Flex flexDir={"column"}>
          <Image
            src={SliderImg2}
            alt="SliderImg2"
            width={"36px"}
            height={"36px"}
          />
        </Flex>

        <Box
          bg={"extsy.neutral300"}
          w={`220px`}
          h="2px"
          borderRadius="full"
        ></Box>
        <Flex flexDir={"column"} gap={"12px"}>
          <Image
            src={SliderImg2}
            alt="SliderImg2"
            width={"36px"}
            height={"36px"}
          />
        </Flex>
      </Flex>
      <Flex
        justify={"space-between"}
        align={"center"}
        mt={"10px"}
        gap={"160px"}
      >
        <CustomText
          text={t("waitingForDeposit")}
          size="18px"
          color="extsy.baseWhite"
          family="Titillium Web"
        />
        <CustomText
          text={t("confirmation")}
          size="18px"
          color="extsy.neutral300"
          family="Titillium Web"
        />
        <CustomText
          text={t("depositConfirmed")}
          size="18px"
          color="extsy.neutral300"
          family="Titillium Web"
        />
      </Flex>
    </Flex>
  );
}

export default DashboardSlider;
