import { Button } from "@chakra-ui/react";
import React from "react";

function CustomButton({
  title,
  height,
  width,
  maxW,
  onClick,
  isLoading,
  isDisabled,
  padding,
  family,
  bg,
  radius,
  color,
  size,
  icon,
}: any) {
  return (
    <Button
      height={height || "56px"}
      width={width || "100%"}
      maxW={maxW || "100%"}
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
      padding={padding ? padding : ""}
      display="flex"
      gap="10px"
      alignItems="center"
      borderRadius={radius || "999px"}
      bg={bg || "extsy.baseWhite"}
      _hover={{
        bg: bg || "extsy.baseWhite",
      }}
      fontFamily={family || "Titillium Web"}
      fontSize={size || "16px"}
      color={color}
    >
      {icon}
      {title}
    </Button>
  );
}

export default CustomButton;
