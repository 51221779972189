import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";
import { IconCaretDownFilled, IconTrashFilled } from "@tabler/icons-react";

function OpenOrders() {
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th border="0" py="16px">
              <CustomText text="Date" color="extsy.neutral400" size="13px" />
            </Th>
            <Th border="0" py="16px">
              <CustomText text="Pair" color="extsy.neutral400" size="13px" />
            </Th>
            <Th border="0" py="16px">
              <Flex align="center" gap="4px">
                <CustomText text="Type" color="extsy.neutral400" size="13px" />
                <IconCaretDownFilled size="16px" color="#848E9C" />
              </Flex>
            </Th>
            <Th border="0" py="16px">
              <Flex align="center" gap="4px">
                <CustomText text="Side" color="extsy.neutral400" size="13px" />
                <IconCaretDownFilled size="16px" color="#848E9C" />
              </Flex>{" "}
            </Th>
            <Th border="0" py="16px">
              <CustomText text="Price" color="extsy.neutral400" size="13px" />
            </Th>
            <Th border="0" py="16px">
              <CustomText text="Amount" color="extsy.neutral400" size="13px" />
            </Th>
            <Th border="0" py="16px">
              <CustomText text="Filled" color="extsy.neutral400" size="13px" />
            </Th>
            <Th border="0" py="16px">
              <CustomText text="Total" color="extsy.neutral400" size="13px" />
            </Th>
            <Th border="0" py="16px">
              <Flex align="center" gap="4px">
                <CustomText text="Cancel All" color="#F0B90B" size="13px" />
                <IconCaretDownFilled size="16px" color="#848E9C" />
              </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {new Array(3).fill(0).map((_, index) => (
            <Tr key={index}>
              <Td border="0" py="16px">
                <CustomText
                  text="2024-08-15 4:05PM"
                  color="extsy.neutral400"
                  size="13px"
                />
              </Td>
              <Td border="0" py="16px">
                <CustomText
                  text="ACA / USDT"
                  color="extsy.neutral400"
                  size="13px"
                />
              </Td>
              <Td border="0" py="16px">
                <CustomText text="Limit" color="extsy.neutral400" size="13px" />
              </Td>
              <Td border="0" py="16px">
                <CustomText text="Buy" color="#0ECB81" size="13px" />
              </Td>
              <Td border="0" py="16px">
                <CustomText
                  text="300.90"
                  color="extsy.neutral400"
                  size="13px"
                />
              </Td>
              <Td border="0" py="16px">
                <CustomText
                  text="1.00000 BNB"
                  color="extsy.neutral400"
                  size="13px"
                />
              </Td>
              <Td border="0" py="16px">
                <CustomText
                  text="0.000 BNB"
                  color="extsy.neutral400"
                  size="13px"
                />
              </Td>
              <Td border="0" py="16px">
                <CustomText text="SOR" color="extsy.neutral400" size="13px" />
              </Td>
              <Td border="0" py="16px">
                <IconTrashFilled size="16px" color="#FAFAFA" />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default OpenOrders;
