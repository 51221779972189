import { Box } from "@chakra-ui/react";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import React from "react";
import Calendar from "react-calendar";

function CustomCalendar({
  value,
  onChange,
  isCalendarOpen,
  isRangePicker,
}: any) {
  return (
    <Box
      pos="absolute"
      borderRadius="16px"
      bg="extsy.neutral1000"
      border="1px solid"
      borderColor="extsy.neutral900"
      width="416px"
      height="416px"
      top="0"
      right="0"
      mt="45px"
      zIndex="100"
      display={isCalendarOpen ? "block" : "none"}
      color="extsy.white"
    >
      <Calendar
        value={value}
        onChange={onChange}
        nextLabel={<IconChevronRight size="20px" color="#fff" />}
        prevLabel={<IconChevronLeft size="20px" color="#fff" />}
        next2Label={null}
        prev2Label={null}
        selectRange={isRangePicker}
      />
    </Box>
  );
}

export default CustomCalendar;
