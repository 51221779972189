import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import BookIco from "../../assets/images/book-ico.svg";
import ArrowsExchange from "../../assets/images/arrows-exchange.svg";
import {
  IconArrowDown,
  IconCaretDownFilled,
  IconCaretUpFilled,
  IconChevronRight,
  IconDots,
  IconSearch,
  IconStarFilled,
} from "@tabler/icons-react";
import { PAIRS } from "../../utils/constants";
import TradingViewChart from "./TradingViewChart";
import OrderBookIco1 from "../../assets/images/order-book-ico1.svg";
import OrderBookIco2 from "../../assets/images/order-book-ico2.svg";
import OrderBookIco3 from "../../assets/images/order-book-ico3.svg";
import BuySell from "./BuySell";
import OpenOrders from "./OpenOrders";
import OrderHistory from "./OrderHistory";
import TradeHistory from "./TradeHistory";

function Spot() {
  const [tabIndex, setTabIndex] = useState(0);

  const TABS = [
    {
      title: "Open Orders",
      key: "openOrders",
      number: "3",
    },
    {
      title: "Order History",
      key: "orderHistory",
      number: "0",
    },
    {
      title: "Trade History",
      key: "tradeHistory",
    },
  ];
  return (
    <Grid
      gap="16px"
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(12, 1fr)"
    >
      <GridItem colSpan={9}>
        <Box ps="16px">
          <Flex gap="38px" align="center">
            <Flex py="12px" gap="28.6px" ps="8px">
              <Flex
                flexDir="column"
                gap="4px"
                pe="29.8px"
                borderRight="1px solid"
                borderColor="#252930"
              >
                <CustomText
                  text="BTC/USDT"
                  size="22.62px"
                  color="extsy.text2"
                />
                <Flex gap="6px" align="center">
                  <Image src={BookIco} alt="book" />
                  <CustomText
                    text="Bitcoin Price"
                    underline
                    color="extsy.neutral400"
                    cursor="pointer"
                  />
                </Flex>
              </Flex>
              <Flex flexDir="column" gap="4px">
                <CustomText
                  text="19,965.74"
                  size="18px"
                  lh="22px"
                  color="#F6465D"
                />
                <CustomText
                  text="₦9,236,949.95"
                  size="13px"
                  color="extsy.text2"
                />
              </Flex>
            </Flex>
            <Flex gap="25px" mt="-8px">
              <Flex flexDir="column" gap="10px">
                <CustomText
                  text="24h Change"
                  size="13px"
                  lh="19px"
                  color="extsy.neutral400"
                />
                <Flex gap="5px">
                  <CustomText
                    text="-1,498.25"
                    size="13px"
                    color="extsy.danger"
                  />
                  <CustomText text="-6.98%" size="13px" color="extsy.danger" />
                </Flex>
              </Flex>
              <Flex flexDir="column" gap="10px">
                <CustomText
                  text="24h High"
                  size="13px"
                  lh="19px"
                  color="extsy.neutral400"
                />
                <CustomText text="21,491.86" size="13px" color="extsy.text2" />
              </Flex>
              <Flex flexDir="column" gap="10px">
                <CustomText
                  text="24h Low"
                  size="13px"
                  lh="19px"
                  color="extsy.neutral400"
                />
                <CustomText text="19,549.09" size="13px" color="extsy.text2" />
              </Flex>
              <Flex flexDir="column" gap="10px">
                <CustomText
                  text="24h Volume(BTC)"
                  size="13px"
                  lh="19px"
                  color="extsy.neutral400"
                />
                <CustomText text="715,559.40" size="13px" color="extsy.text2" />
              </Flex>
              <Flex flexDir="column" gap="10px">
                <CustomText
                  text="24h Volume(USDT)"
                  size="13px"
                  lh="19px"
                  color="extsy.neutral400"
                />
                <CustomText
                  text="14,430,472,197.94"
                  size="13px"
                  color="extsy.text2"
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex gap="16px">
            <Box maxW="349px" width="100%">
              <Box pos="relative">
                <IconSearch
                  size="14px"
                  color="#5E6673"
                  cursor="pointer"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    marginLeft: "5px",
                    marginTop: "6px",
                  }}
                />
                <Input
                  minW="100%"
                  type="text"
                  bg="extsy.dark400"
                  borderRadius="4px"
                  border="none"
                  height="28px"
                  placeholder="Search"
                  fontSize="14px"
                  py="0px"
                  pl="25px"
                  color="extsy.white"
                  fontFamily="Metropolis"
                  _placeholder={{
                    color: "extsy.text3",
                  }}
                />
              </Box>
              <Flex
                gap="10px"
                align="center"
                mt="14.3px"
                pt="8px"
                pb="6px"
                ps="4px"
                pos="relative"
                overflowX="auto"
              >
                <IconStarFilled color="#848E9C" size="14.6px" />
                <CustomText
                  text="Margin"
                  size="14.3px"
                  color="extsy.neutral400"
                  mt="3px"
                />
                <CustomText
                  text="BUSD"
                  size="14.3px"
                  color="extsy.neutral400"
                  mt="3px"
                />
                <CustomText
                  text="USDT"
                  size="14.3px"
                  color="extsy.neutral400"
                  mt="3px"
                />
                <CustomText
                  text="BNB"
                  size="14.3px"
                  color="extsy.neutral400"
                  mt="3px"
                />
                <CustomText text="BTC" size="14.3px" color="#F0B90B" mt="3px" />
                <Flex gap="4px" align="center">
                  <CustomText
                    text="ALTS"
                    size="14.3px"
                    color="extsy.neutral400"
                    mt="3px"
                  />
                  <IconCaretDownFilled color="#848E9C" size="13px" />
                </Flex>
                <Flex
                  bg="linear-gradient(270deg, #161A1E 42.24%, rgba(0, 0, 0, 0.00) 95.69%)"
                  w="69px"
                  justify="end"
                  pos="absolute"
                  right="0"
                >
                  <IconChevronRight color="#76808F" size="23px" />
                </Flex>
              </Flex>
              <TableContainer>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th minW="125px" border="none" ps="0" py="2px">
                        <Flex align="center" gap="2px">
                          <CustomText
                            text="Pair"
                            size="13px"
                            color="extsy.neutral400"
                          />
                          <Flex flexDir="column">
                            <IconCaretUpFilled color="#EFB80B" size="10px" />
                            <IconCaretDownFilled
                              color="#848E9C"
                              size="10px"
                              style={{
                                marginTop: "-6px",
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Th>
                      <Th border="none" minW="90px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text="Price"
                            size="13px"
                            color="extsy.neutral400"
                          />
                          <Flex flexDir="column">
                            <IconCaretUpFilled color="#848E9C" size="10px" />
                            <IconCaretDownFilled
                              color="#848E9C"
                              size="10px"
                              style={{
                                marginTop: "-6px",
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Th>
                      <Th border="none" p="0" minW="80px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text="Change"
                            size="13px"
                            color="extsy.neutral400"
                          />
                          <Flex flexDir="column">
                            <IconCaretUpFilled color="#848E9C" size="10px" />
                            <IconCaretDownFilled
                              color="#848E9C"
                              size="10px"
                              style={{
                                marginTop: "-6px",
                              }}
                            />
                          </Flex>
                          <Image src={ArrowsExchange} alt="exchange" />
                        </Flex>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {PAIRS.map((pair, index) => (
                      <Tr key={index}>
                        <Td minW="125px" border="none" ps="0">
                          <Flex align="center" gap="2px">
                            <IconStarFilled
                              color="#848E9C"
                              size="14.6px"
                              style={{
                                marginTop: "-3px",
                              }}
                            />
                            <CustomText
                              text={
                                <>
                                  <span style={{ color: "#EAECEF" }}>
                                    {pair?.name}
                                  </span>
                                  /BTC
                                </>
                              }
                              size="13px"
                              color="extsy.neutral400"
                            />
                          </Flex>
                        </Td>
                        <Td border="none" minW="90px">
                          <Flex align="center" gap="2px" justify="end">
                            <CustomText
                              text={pair.price}
                              size="13px"
                              color="extsy.text4"
                            />
                          </Flex>
                        </Td>
                        <Td border="none" p="0" minW="80px">
                          <Flex align="center" gap="2px" justify="end">
                            <CustomText
                              text={pair.change}
                              size="13px"
                              color={
                                pair?.change?.includes("-")
                                  ? "extsy.danger"
                                  : "#0ECB81"
                              }
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <TableContainer mt="5px">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th minW="125px" border="none" ps="0">
                        <CustomText
                          text="Price(USDT)"
                          size="13px"
                          color="extsy.neutral400"
                        />
                      </Th>
                      <Th border="none" minW="90px">
                        <CustomText
                          text="Amount(BTC)"
                          size="13px"
                          color="extsy.neutral400"
                          align="end"
                        />
                      </Th>
                      <Th border="none" p="0" minW="80px">
                        <CustomText
                          text="Time"
                          size="13px"
                          color="extsy.neutral400"
                          align="end"
                        />
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {new Array(20)?.fill(1).map((pair, index) => (
                      <Tr key={index}>
                        <Td minW="125px" border="none" ps="0">
                          <Flex align="center" gap="2px">
                            <CustomText
                              text={"19,965.74"}
                              size="13px"
                              color="extsy.danger"
                            />
                          </Flex>
                        </Td>
                        <Td border="none" minW="90px">
                          <Flex align="center" gap="2px" justify="end">
                            <CustomText
                              text={"0.13624"}
                              size="13px"
                              color="extsy.text4"
                            />
                          </Flex>
                        </Td>
                        <Td border="none" p="0" minW="80px">
                          <Flex align="center" gap="2px" justify="end">
                            <CustomText
                              text={"18:43:07"}
                              size="13px"
                              color="extsy.text4"
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
            <Flex flexDir="column" gap="40px" w="100%">
              <TradingViewChart />
              <BuySell />
            </Flex>
          </Flex>
        </Box>
      </GridItem>
      <GridItem colSpan={3}>
        <Flex justify="space-between" align="center" pe="16px">
          <CustomText
            text="Order Book"
            color="extsy.text2"
            size="15px"
            weight="700"
          />
          <IconDots color="#3D4653" size="20px" />
        </Flex>
        <Flex
          mt="16px"
          justify="space-between"
          gap="19px"
          align="center"
          pe="16px"
        >
          <Flex gap="19px">
            <Image src={OrderBookIco1} alt="ico" />
            <Image src={OrderBookIco2} alt="ico" />
            <Image src={OrderBookIco3} alt="ico" />
          </Flex>
          <Flex align="center" gap="6px">
            <CustomText text="0.01" size="13px" color="extsy.text2" />
            <IconCaretDownFilled color="#848E9C" size="19px" />
          </Flex>
        </Flex>
        <TableContainer mt="11px" pe="16px">
          <Table size="sm">
            <Thead>
              <Tr>
                <Th minW="125px" border="none" ps="0">
                  <CustomText
                    text="Price(USDT)"
                    size="13px"
                    color="extsy.neutral400"
                  />
                </Th>
                <Th border="none" minW="90px">
                  <CustomText
                    text="Amount(BTC)"
                    size="13px"
                    color="extsy.neutral400"
                    align="end"
                  />
                </Th>
                <Th border="none" p="0" minW="80px">
                  <CustomText
                    text="Time"
                    size="13px"
                    color="extsy.neutral400"
                    align="end"
                  />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {new Array(20)?.fill(1).map((pair, index) => (
                <Tr key={index}>
                  <Td h="23px" minW="125px" border="none" p="0">
                    <Flex align="center" gap="2px">
                      <CustomText
                        text={"19,965.74"}
                        size="13px"
                        color="extsy.danger"
                      />
                    </Flex>
                  </Td>
                  <Td border="none" minW="90px">
                    <Flex align="center" gap="2px" justify="end">
                      <CustomText
                        text={"0.13624"}
                        size="13px"
                        color="extsy.text4"
                      />
                    </Flex>
                  </Td>
                  <Td border="none" p="0" minW="80px">
                    <Flex align="center" gap="2px" justify="end">
                      <CustomText
                        text={"18:43:07"}
                        size="13px"
                        color="extsy.text4"
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex
          justify="space-between"
          align="center"
          gap="19px"
          py="13px"
          pe="16px"
        >
          <Flex gap="6px" align="center">
            <CustomText text="19,965.74" size="22px" color="extsy.danger" />
            <IconArrowDown color="#F6465D" size="19px" />
            <CustomText
              text="₦9,236,949.95"
              size="13px"
              color="extsy.neutral400"
            />
          </Flex>
          <CustomText text="More" size="14px" color="extsy.neutral400" />
        </Flex>
        <TableContainer pe="16px">
          <Table size="sm">
            <Tbody>
              {new Array(16)?.fill(1).map((pair, index) => (
                <Tr key={index}>
                  <Td h="23px" minW="125px" border="none" p="0">
                    <Flex align="center" gap="2px">
                      <CustomText
                        text={"19,965.74"}
                        size="13px"
                        color="#0ECB81"
                      />
                    </Flex>
                  </Td>
                  <Td border="none" minW="90px">
                    <Flex align="center" gap="2px" justify="end">
                      <CustomText
                        text={"0.13624"}
                        size="13px"
                        color="extsy.text4"
                      />
                    </Flex>
                  </Td>
                  <Td border="none" p="0" minW="80px">
                    <Flex align="center" gap="2px" justify="end">
                      <CustomText
                        text={"18:43:07"}
                        size="13px"
                        color="extsy.text4"
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </GridItem>
      <GridItem colSpan={12}>
        <Tabs
          onChange={(index) => setTabIndex(index)}
          defaultIndex={0}
          borderBottomRadius="16px"
        >
          <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
            {TABS?.map((tab, index) => (
              <Tab key={index} color="#F0B90B" display="flex" gap="4px">
                <CustomText
                  text={tab?.title}
                  size={index === tabIndex ? "16px" : "15px"}
                  weight="400"
                  color={
                    index === tabIndex ? "extsy.white" : "extsy.neutral400"
                  }
                  cursor="pointer"
                />
                {tab?.number && (
                  <CustomText
                    text={`(${tab?.number || 0})`}
                    size="16px"
                    weight="400"
                    color={
                      index === tabIndex ? "extsy.white" : "extsy.neutral400"
                    }
                  />
                )}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              <OpenOrders />
            </TabPanel>
            <TabPanel>
              <OrderHistory />
            </TabPanel>
            <TabPanel>
              <TradeHistory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </GridItem>
    </Grid>
  );
}

export default Spot;
