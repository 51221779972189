import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import TradingEstimateBalanceCard from "../../components/cards/TradingEstimateBalanceCard";
import CustomText from "../../components/text/CustomText";
import Spot from "../../components/trading/Spot";
import Future from "../../components/trading/Future";

function Trading() {
  const [tabIndex, setTabIndex] = useState(0);

  const TABS = ["Spot", "Futures"];

  return (
    <Flex gap="24px" flexDir="column">
      <TradingEstimateBalanceCard />
      <Box bg="extsy.neutral1000" borderRadius="24px" pt="20px">
        <Tabs onChange={(index) => setTabIndex(index)} defaultIndex={0}>
          <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
            {TABS?.map((tab, index) => (
              <Tab
                key={index}
                px="32px"
                pt="10px"
                pb="14px"
                color="extsy.secondary100"
              >
                <CustomText
                  text={tab}
                  size="18px"
                  weight="600"
                  color={
                    index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                  }
                  cursor="pointer"
                />
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel pe="0" pt="28px" ps="0px">
              <Spot />
            </TabPanel>
            <TabPanel pe="0" pt="28px" ps="0px">
              <Future />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
}

export default Trading;
