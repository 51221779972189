import { Box, Flex, Input, Tab, TabList, Tabs } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../../components/text/CustomText";
import TableSearchInput from "../../../components/form/TableSearchInput";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import BuyTable from "./BuyTable";

function Buy() {
  const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
  const [isRefreshOpen, setIsRefreshOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState("$");
  const [refreshAd, setRefreshAd] = useState("Refresh Ad");

  const TABS = ["USDT", "USDC", "BTC", "BNB", "ETH"];
  const CURRENCIES = ["$", "€", "£", "¥"];
  const REFRESH_ADS = ["Every 5s", "Every 10s", "Every 20s"];

  return (
    <div>
      <Flex align="flex-start" justify="space-between">
        <Tabs onChange={(index) => setTabIndex(index)} defaultIndex={0}>
          <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
            {TABS?.map((tab, index) => (
              <Tab
                key={index}
                px="32px"
                pt="10px"
                pb="14px"
                color="extsy.secondary100"
              >
                <CustomText
                  text={tab}
                  color={
                    index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                  }
                  cursor="pointer"
                />
              </Tab>
            ))}
          </TabList>
        </Tabs>
        <Flex align="center" gap="10px">
          <TableSearchInput placeholder="Search" />
          <Box pos="relative">
            <Flex
              align="center"
              gap="32px"
              h="38px"
              ps="24px"
              pe="10px"
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
            >
              <Input
                h="38px"
                py="0"
                pl="0"
                border="none"
                type="number"
                placeholder="Transaction Amount"
                fontSize="12px"
                fontFamily="Titillium Web"
                color="extsy.baseWhite"
                _placeholder={{ color: "extsy.neutral500" }}
                _hover={{
                  border: "none",
                }}
                _focus={{
                  border: "none",
                }}
                _focusVisible={{ border: "none" }}
              />
              <Flex
                align="center"
                gap="8px"
                cursor="pointer"
                onClick={() => {
                  setIsCurrencyOpen(!isCurrencyOpen);
                  setIsRefreshOpen(false);
                }}
              >
                <CustomText text="|" size="12px" />
                <CustomText
                  text={selectedCurrency}
                  size="12px"
                  color="extsy.baseWhite"
                />
                <IconChevronDown size="16px" color="#fafafa" />
              </Flex>
            </Flex>
            <Flex
              p="16px"
              borderRadius="8px"
              bg="extsy.bg"
              flexDir="column"
              gap="16px"
              w="100px"
              display={isCurrencyOpen ? "flex" : "none"}
              pos="absolute"
              right="0"
              top="0"
              mt="45px"
            >
              {CURRENCIES.map((currency, index) => (
                <Flex
                  key={index}
                  align="center"
                  justify="space-between"
                  gap="8px"
                  cursor="pointer"
                  onClick={() => {
                    setSelectedCurrency(currency);
                    setIsCurrencyOpen(false);
                  }}
                >
                  <CustomText
                    text={currency}
                    size="14px"
                    color={
                      currency === selectedCurrency
                        ? "extsy.baseWhite"
                        : "extsy.neutral300"
                    }
                    cursor
                  />
                  {currency === selectedCurrency && (
                    <IconCheck size="16px" color="#fafafa" />
                  )}
                </Flex>
              ))}
            </Flex>
          </Box>
          <Box pos="relative">
            <Flex
              align="center"
              gap="32px"
              h="38px"
              ps="24px"
              pe="10px"
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              onClick={() => {
                setIsRefreshOpen(!isRefreshOpen);
                setIsCurrencyOpen(false);
              }}
            >
              <CustomText
                text={refreshAd}
                size="12px"
                color="extsy.baseWhite"
              />
              <IconChevronDown size="16px" color="#fafafa" />
            </Flex>
            <Flex
              p="16px"
              borderRadius="8px"
              bg="extsy.bg"
              flexDir="column"
              gap="16px"
              w="100px"
              display={isRefreshOpen ? "flex" : "none"}
              pos="absolute"
              right="0"
              top="0"
              mt="45px"
            >
              {REFRESH_ADS.map((interval, index) => (
                <Flex
                  key={index}
                  align="center"
                  justify="space-between"
                  gap="8px"
                  cursor="pointer"
                  onClick={() => {
                    setRefreshAd(interval);
                    setIsRefreshOpen(false);
                  }}
                >
                  <CustomText
                    text={interval}
                    size="14px"
                    color={
                      interval === refreshAd
                        ? "extsy.baseWhite"
                        : "extsy.neutral300"
                    }
                    cursor
                  />
                  {interval === refreshAd && (
                    <IconCheck size="16px" color="#fafafa" />
                  )}
                </Flex>
              ))}
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Box mt="40px">
        <BuyTable />
      </Box>
    </div>
  );
}

export default Buy;
